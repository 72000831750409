import axios from 'axios';
import { GET_SERIES } from './action.types';
import { API_URL } from '../../config';

function nextChar(c) {
  return String.fromCharCode(c.charCodeAt(0) + 1);
}

export const getSeries = (startsWith, genre, sortBy, startsAt) => async (dispatch) => {
  let sort = '_sort=popularity:DESC';
  if (sortBy && sortBy!=='undefined') {
    sort = '_sort='+sortBy+':DESC' 
  }
  if ( sortBy ==='recent') {
    sort = '_sort=createdAt:DESC';
  }
  if ( sortBy ==='rating') {
    sort = '_sort=vote_average:DESC';
  }
  if ( sortBy ==='year') {
    sort = '_sort=release_date:DESC';
  }
  let starts = '';
  if (startsWith && startsWith !=='Z' && isNaN(startsWith) && startsWith !=='undefined' && startsWith !=='ALL') {
    starts = '&title_gte='+startsWith+'&title_lt='+nextChar(startsWith);
  }
  if (startsWith ==='Z' && startsWith !=='undefined' && startsWith !=='ALL') {
    starts = '&title_gte='+startsWith+'&title_lt=a';
  }
  if ( startsWith === '0-9') {
    starts = '&title_gte=0&title_lte=9';
  }
  let genres ='';
  if (genre && genre.length > 0 && genre!=='undefined') {
    genre.forEach(item => {
      if (item !== '') genres += '&genres.tmdbId_in='+item;

    });
  }
  let page ='';
  if (startsAt && startsAt !=='undefined') {
    page = '&_start='+(Number(startsAt)-1)*40;
  }
  const series = await axios
  .get(`${API_URL}series?${sort}${starts}${genres}${page}&seasonNumber=1&episodeNumber=1&_limit=81`)
    .then((res) => res.data)
    .catch(() => []);

  dispatch({
    type: GET_SERIES,
    payload: series,
  });
};

export default getSeries;

import axios from 'axios';
import Swal from 'sweetalert2';

export const axiosIntercepts = () => {
  axios.interceptors.request.use(async (config) => {
    // if its a request to back-end(contain origin url or a path that stats with /) add auth token
      const token = localStorage.getItem('token');
      if (config.url.indexOf(window.location.origin) > -1 || config.url[0] === '/') {
      config.headers.Authorization = `Bearer ${token}`;
      }
    return config;
  });
  const interceptor = axios.interceptors.response.use(response => response, (error) => {
    // Reject promise if usual error
    if ( error && error.response && error.response.status === 409) {
      localStorage.removeItem('token');
      console.log(window.location)
      if (window.location.pathname !== '/login' && window.location.pathname !== '/register') {
      window.location.href = '/login';
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
    /*
     * When response code is 401, try to refresh the token.
     * Eject the interceptor so it doesn't loop in case
     * token refresh causes the 401 response
     */
  });
  return interceptor;
};

export default axiosIntercepts;

import { GET_NEW_EPISODES } from '../actions/action.types';

export default (state = [], action) => {
  switch (action.type) {
    case GET_NEW_EPISODES:
      return action.payload || [];
    default:
      return state;
  }
};

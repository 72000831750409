import axios from 'axios';
import { GET_FRONT_PAGE, GET_STATISTICS } from './action.types';
import { API_URL } from '../../config';

export const getFrontPage = () => (dispatch, getState) => {
  const popularMovies = axios.get(`${API_URL}movies?_sort=popularity:DESC&_limit=16`).then((res) => {
    const { frontPage } = getState();
    frontPage.popularMovies = res.data;
    dispatch({
      type: GET_FRONT_PAGE,
      payload: { ...frontPage },
    })
  });
  const popularEpisodes = axios.get(`${API_URL}series?_sort=popularity:DESC&_limit=16&seasonNumber=1&episodeNumber=1`).then((res) => {
    const { frontPage } = getState();
    frontPage.popularEpisodes = res.data;
    dispatch({
      type: GET_FRONT_PAGE,
      payload: { ...frontPage },
    })
  });
  const recent = axios.get(`${API_URL}movies?_sort=createdAt:DESC&_limit=16`).then((res) => {
    const { frontPage } = getState();
    frontPage.recent = res.data;
    dispatch({
      type: GET_FRONT_PAGE,
      payload: { ...frontPage },
    })
  });
  const recentEpisodes = axios.get(`${API_URL}series?_sort=createdAt:DESC&_limit=16&seasonNumber=1&episodeNumber=1`).then((res) => {
    const { frontPage } = getState();
    frontPage.recentEpisodes = res.data;
    dispatch({
      type: GET_FRONT_PAGE,
      payload: { ...frontPage },
    })
  });
  const gross = axios.get(`${API_URL}movies?_sort=revenue:DESC&_start=${Math.floor(Math.random() * 16)*Math.floor(Math.random() * 16)}&_limit=16`).then((res) => {
    const { frontPage } = getState();
    frontPage.topGross = res.data;
    dispatch({
      type: GET_FRONT_PAGE,
      payload: { ...frontPage },
    })
  });
  axios.get(`/api/statistics`).then((res) => {
    dispatch({
      type: GET_STATISTICS,
      payload: {...res.data},
    })
  })
};

export default getFrontPage;

import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import bg from '../img/movie-item-bg.jpg';

const ItemWrapper = styled.div`
position: relative;
    font-size: 15px;
    display: inline-block;
    width: ${(props) => (!props.fullSize ? 'calc(100% / 10)' : '100%')};
    padding: 10px;
    text-align: left;
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
    -ms-transform: perspective(1000px);
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    ${(props) => (!props.fullSize ? `
@media (max-width: 1920px) {
  width: calc(100% / 9);
}
@media (max-width: 1700px) {
  width: calc(100% / 8);
}
@media (max-width: 1400px) {
  width: calc(100% / 7);
}
@media (max-width: 1200px){
  width: calc(100% / 6);
}
@media (max-width: 800px){
  width: calc(100% / 4);
}

@media (max-width: 500px){
  width: calc(100% / 2);
}
` : '')}

& > div {
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -moz-transition: 0.6s;
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

& .thumb {
  background-position: left top;
  background-repeat: no-repeat;
  border-radius: 4px;
  overflow: hidden;
  min-height: 100px;
}
& .info {
  background: rgba(0, 0, 0, 0) linear-gradient(-180deg, rgba(12, 14, 16, 0.7) 0%, #0b0b0b 84%) repeat scroll 0 0;
  border-radius: 4px;
  bottom: 0;
  height: 100%;
  left: 0;
  padding: 0px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 4;
  border: 1px solid #141414;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.2s ease-in 0s;
}

& .info:hover {
opacity: 1;
}
`;

const ItemDetailsInner = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  padding: 10px;
  left: 0px;
  right: 0px;

  & h2{
    color: #fff;
    font-size: 19px;
    font-weight: 400;
  }

  & .rating{
    bottom: 5px;
    left: 10px;
    position: absolute;
    right: 10px;
  }

  & .rating > div {
    width: auto;
    font-size: 14px;
    padding: 1px 7px;
    border-radius: 2px;
  }

  & .container {
    float: left;
    border: 1px solid rgba(255, 255, 255, 0.31);
  }

  & .container > a{
    transition: .3s ease;
    color: #fff;
  }

  &  i {
    float: left;
    padding-right: 5px;
    margin-top: 2px;
    font-size: 10px;
    line-height: 16px;
  }

  & span {
    border-radius: 100px;
    display: block;
    float: left;
    margin-top: 1px;
    line-height: 20px;
    padding-left: 0px;
    padding-right: 0px;
    transition: background-color 0.25s ease-in-out 0s;
    width: auto;
  }

  & .mfYear {
    float: right;
    line-height: 26px;
    text-align: right;
  }

`;

const MovieItem = ({ item, fullSize, reload }) => (
  <ItemWrapper fullSize={fullSize}>
    <div>
      {reload && (
        <a href={`/movie/${item.imdb_id}-${encodeURI(item.title.split(' ').join('-').split('/').join('-'))}`} style={{ textDecoration: 'none', color: 'white' }}>
        <div className="thumb" style={{position: 'relative' }}>
          <img src={bg} style={{width: '100%' }}/>
          <img
            src={`https://image.tmdb.org/t/p/w342${item.poster}`}
            style={{ width: '100%', position: 'absolute', top: 0, left: 0 }}
            alt="title"
          />
        </div>
        <div className="info">
          <ItemDetailsInner>

            <h2 className="movie-card-title">{item.title}</h2>
            <div className="rating">
              <div className="container">
                <i className="fas fa-star" />
                <span className="ratingNumber">{Number(item.vote_average).toFixed(1)}</span>
              </div>
              <div className="mfYear">
                {item.release_date && item.release_date.substring(0, 4)}
              </div>
            </div>
          </ItemDetailsInner>
        </div>
      </a>
      )}
      {!reload && (
        <Link to={`/movie/${item.imdb_id}-${encodeURI(item.title.split(' ').join('-').split('/').join('-'))}`} style={{ textDecoration: 'none', color: 'white' }}>
        <div className="thumb" style={{position: 'relative' }}>
          <img src={bg} style={{width: '100%' }}/>
          <img
            src={`https://image.tmdb.org/t/p/w342${item.poster}`}
            style={{ width: '100%', position: 'absolute', top: 0, left: 0 }}
            alt="title"
          />
        </div>
        <div className="info">
          <ItemDetailsInner>

            <h2 className="movie-card-title">{item.title}</h2>
            <div className="rating">
              <div className="container">
                <i className="fas fa-star" />
                <span className="ratingNumber">{Number(item.vote_average).toFixed(1)}</span>
              </div>
              <div className="mfYear">
                {item.release_date && item.release_date.substring(0, 4)}
              </div>
            </div>
          </ItemDetailsInner>
        </div>
      </Link>
      )}
      
    </div>
  </ItemWrapper>
);

export default MovieItem;

import { GET_NOTE } from './action.types'
import axios from 'axios';

export const checkNote = () => async (dispatch) => {
  const note = await axios.get('/api/get-note')
    .then((res) => res.data)
    .catch(() => false);

  if (note) {
    if (localStorage.getItem('note') !== note.updatedAt) {
      dispatch({
        type: GET_NOTE,
        payload: note,
      });
      return true;
    }
  }
  return false;
};

export const dimissNote = (date) => async (dispatch) => {
  localStorage.setItem('note', date)
}
import { GET_REFERRALS } from '../actions/action.types';

export default (state = [], action) => {
  switch (action.type) {
    case GET_REFERRALS:
      return action.payload;
    default:
      return state;
  }
};

import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import Axios from "axios";
import Swal from "sweetalert2";

const MainWrapper = styled.div`
  position: relative;
  color: white;
  display: block;
  vertical-align: top;
  background: #1b1b1b;
  width: calc(100% - 100px);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin: 70px 0 0 100px;
  height: 100%;
  min-height: calc(100vh - 70px);
  z-index: 1;
`;

const InnerContainer = styled.section`
  padding: 0 30px;
  margin: auto;
  padding-bottom: 30px;
  width: 80%;
  max-width: 1300px;
  position: relative;
  padding-top: 30px;
  text-align: center;

  & h1 {
    padding: 60px 0 20px 0;
    font-size: 40px;
    font-weight: 300;
    line-height: 1.5em;
  }

  &.profile > i {
    font-size: 64px;
    color: #fff;
    position: relative;
    background: #298eea;
    display: block;
    width: 80px;
    margin: 30px auto;
    padding: 16px 0 0 0;
    text-align: center;
    border-radius: 50%;
    border: 15px solid #141414;
    box-sizing: content-box;
    overflow: hidden;
  }
`;

const AccountTabs = styled.ul`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  list-style: none;

  & li {
    display: inline-block;
    font-size: 15px;
    margin-bottom: -2px;
  }

  & li a {
    display: inline-block;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 600;
    padding: 0 20px;
    border-bottom: 1px solid transparent;
    text-decoration: none;
    transition: 0.3s ease;
  }

  & li a:hover span {
    border-color: rgba(255, 255, 255, 0.2);
  }

  & li a span {
    display: inline-block;
    padding: 15px 0px;
    border-bottom: 1px solid transparent;
  }

  & li.active a span {
    color: #fff;
    border-color: #fff;
  }
`;

const UserInfo = styled.div`
  display: inline-block;
  padding: 80px 80px 60px 80px;
  @media screen and (max-width: 770px) {
    padding: 40px 20px 15px 20px;
  }
  background: rgba(255, 255, 255, 0.08);
  border-radius: 3px;
  margin-top: -65px;

  & h3 {
    line-height: 1;
    padding-bottom: 20px;
    font-size: 24px;
    font-weight: 300;
  }

  & h3 i {
    font-size: 20px;
  }

  & p {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 1.5em;
  }

  & .account-status {
    color: #298eea;
    font-weight: 700;
  }

  & .premiumtime {
    color: #298eea;
    font-weight: 700;
  }
`;

const ProfileActions = styled.ul`
  margin-top: 50px;
  list-style: none;
  padding-top: 15px;

  & li:first-child {
    padding-right: 10px;
  }

  & li {
    display: inline-block;
    width: 50%;
    font-size: 14px;
    line-height: 1.5em;
  }

  & li:last-child {
    padding-left: 10px;
  }

  & li span {
    display: block;
    font-weight: 600;
    padding: 15px 25px;
    color: #fff;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 4px;
    cursor: pointer;
  }

  & li span:hover {
    background: #298eea;
  }
`;

const User = (props) => {
  const user = useSelector((redux) => redux.user);
  console.log(user);
  const handleChangeName = () => {
    Swal.fire({
      title: "Change name",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Change",
      showLoaderOnConfirm: true,
      preConfirm: (name) => {
        console.log(name);
        if (!name) {
          Swal.showValidationMessage(`Request failed: empty name`);
          return;
        }
        return Axios.post("/api/changeName", {
          name,
        })
          .then((response) => {
            window.location.reload();
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  };

  const handleChangePassword = () => {
    Axios.post("https://admin.nouwall.to/auth/forgot-password", {
      email: user.username,
      url: "https://admin.nouwall.to/admin/plugins/users-permissions/auth/reset-password",
    })
      .then((response) => {
        Swal.fire(
          "Sent",
          "Please check your email for password changing link.",
          "success"
        );
      })
      .catch((error) => {
        Swal.fire("Error", "Please try again later.", "error");
        console.log(error);
      });
  };

  const isPremium = () => {
    if (!user) return "0 days 0 hours";
    if (user && !user.premiumUntil) return "0 days 0 hours";
    const date_future = new Date(user.premiumUntil);
    const date_now = new Date();
    if (date_future.getTime() - date_now.getTime() >= 0) {
      let seconds = Math.floor((date_future - date_now) / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);

      hours = hours - days * 24;
      minutes = minutes - days * 24 * 60 - hours * 60;
      seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
      return `${days} days ${hours} hours`;
    } else {
      return "0 days 0 hours";
    }
  };
  return (
    <MainWrapper>
      <InnerContainer className="profile">
        <AccountTabs>
          <li className="active">
            <Link to="/user">
              <span>Settings</span>
            </Link>
          </li>
          <li>
            <Link to="/user/invite">
              <span>Referrals</span>
            </Link>
          </li>
          <li>
            <Link to="/user/watchlist">
              <span>Watchlist</span>
            </Link>
          </li>
          <li>
            <Link to="/user/favorites">
              <span>Favorites</span>
            </Link>
          </li>
        </AccountTabs>
        <h1>Settings</h1>
        <i className="fas fa-user" />
        <UserInfo>
          <h3>
            Hi{" "}
            {user.name
              ? user.name
              : user.username
              ? user.username.split("@")[0]
              : ""}
          </h3>
          <p>
            <strong>Account status:</strong>
            <br />
            <span className="account-status">
              {isPremium() !== "0 days 0 hours" ? "Premium" : "Free Plan"}
            </span>
            {isPremium() !== "0 days 0 hours" && (
              <>
                {" "}
                user with <span className="premiumtime">
                  {isPremium()}
                </span>{" "}
                remaining.
                <br />
                You have unlimited access to all movies and TV shows!
              </>
            )}
            {isPremium() === "0 days 0 hours" && !isNaN(user.timeLeft) && (
              <>
                {" "}
                user with{" "}
                <span className="premiumtime">
                  {Math.round(user.timeLeft * 60)}
                </span>{" "}
                free minutes remaining.
              </>
            )}
          </p>
          {isPremium() !== "0 days 0 hours" && (
            <p>
              <em>
                Your current subscription is set to not renew automatically.
              </em>
            </p>
          )}
          <ProfileActions>
            <li onClick={handleChangeName}>
              <span>Change name</span>
            </li>
            <li onClick={handleChangePassword}>
              <span>Change password</span>
            </li>
          </ProfileActions>
        </UserInfo>
      </InnerContainer>
    </MainWrapper>
  );
};

export default User;

import { combineReducers } from 'redux';
import moviesReducer from './movies';
import seriesReducer from './series';
import frontPage from './frontPage';
import user from './user';
import note from './note';
import downloads from './downloads';
import series from './series';
import newEpisodes from './newEpisodes';
import statistics from './statistics';
import referrals from './referrals';

export default combineReducers({
  movies: moviesReducer,
  series: seriesReducer,
  frontPage,
  user,
  note,
  downloads,
  series,
  newEpisodes,
  statistics,
  referrals,
});

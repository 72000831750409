import { createStore, compose, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';

import rootReducer from './reducers';

export default function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    compose(
      // reactReduxFirebase(firebase, rrfConfig), // pass in firebase instance instead of config
      // reduxFirestore(firebase) // <- needed if using firestore
      applyMiddleware(reduxThunk), // to add other middleware
    ),
  );
  return store;
}

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { Provider } from "react-redux";
import getStore from "./redux/store";
import { Helmet } from "react-helmet";

import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Home from "./pages/Home";
import Premium from "./pages/Premium";
import Contact from "./pages/Contact";
import Movies from "./pages/Movies";
import Movie from "./pages/Movie";
import Series from "./pages/Series";
import Serie from "./pages/Serie";
import User from "./pages/User";
import Watchlist from "./pages/Watchlist";
import Favorites from "./pages/Favorites";
import Invite from "./pages/Invite";
import Login from "./pages/Login";
import NewEpisodes from "./pages/NewEpisodes";
import ForgotPassword from "./pages/ForgotPassword";
import Register from "./pages/Register";
import Request from "./pages/Request";
import RequestAdd from "./pages/RequestAdd";
import axiosIntercepts from "./axiosInterceptor";
import "./App.css";
import IsAuth from "./components/IsAuth";
import Downloads from "./components/Downloads";
import EmailConfirmed from "./pages/emailConfirmed";
import Search from "./pages/Search";

const store = getStore();

axiosIntercepts();

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta charSet="uft-8" />
        <title>Wonulla</title>
        <link rel="canonical" href="https://wonulla.to/" />
        <meta
          name="description"
          content="Wonulla offer you lots of movies and TV shows."
        />
      </Helmet>
      <Provider store={store}>
        <Router>
          <IsAuth />
          <Downloads />
          <Header />
          <Sidebar />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/movies">
              <Movies />
            </Route>
            <Route exact path="/user/invite">
              <Invite />
            </Route>
            <Route exact path="/search">
              <Search />
            </Route>
            <Route exact path="/series">
              <Series />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/movie/:id">
              <Movie />
            </Route>
            <Route exact path="/serie/:id">
              <Serie />
            </Route>
            <Route exact path="/new-episodes">
              <NewEpisodes />
            </Route>
            <Route exact path="/premium">
              <Premium />
            </Route>
            <Route exact path="/user">
              <User />
            </Route>
            <Route exact path="/user/watchlist">
              <Watchlist />
            </Route>
            <Route exact path="/user/favorites">
              <Favorites />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/forgot-password/:code">
              <ForgotPassword />
            </Route>
            <Route exact path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route exact path="/register">
              <Register />
            </Route>
            <Route exact path="/request">
              <Request />
            </Route>
            <Route exact path="/request/add">
              <RequestAdd />
            </Route>
            <Route exact path="/email-confirmed">
              <EmailConfirmed />
            </Route>
            <Route
              exact
              path="/auth/:code"
              render={(props) => {
                window.location.href =
                  "https://admin.wonulla.to/auth/email-confirmation?confirmation=" +
                  props.match.params.code;
                return null;
              }}
            />
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;

export const GET_FRONT_PAGE = 'GET_FRONT_PAGE';
export const GET_MOVIES = 'GET_MOVIES';
export const GET_SERIES = 'GET_SERIES';
export const GET_NEW_EPISODES = 'GET_NEW_EPISODES';
export const GET_CURRENT_ITEM = 'GET_CURRENT_ITEM';
export const AUTH_USER = 'AUTH_USER';
export const GET_NOTE = 'GET_NOTE';
export const GET_DOWNLOADS = 'GET_DOWNLOADS';
export const GET_STATISTICS = 'GET_STATISTICS';
export const GET_REFERRALS = 'GET_REFERRALS';


import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import Swal from 'sweetalert2';
import { registerUser } from '../redux/actions/user.actions';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

const Background = styled.div`
position: relative;
display: block;
vertical-align: top;
background: linear-gradient( to left, rgba(27, 27, 27, 1),rgba(27, 27, 27, 0.3) ), url(https://i.pinimg.com/originals/44/6e/3b/446e3b79395a287ca32f7977dd83b290.jpg);
background-size: cover;
min-height: 100vh;
width: 50%;
@media screen and (max-width: 770px) {
  display: none;
}
bottom: 0px;
right: 0px;
top: 0px;
height: 100%;
left: 0px;
float: left;
`;

const FireFlyWrapper = styled.div`
.firefly {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 0.4vw;
  height: 0.4vw;
  margin: -0.2vw 0 0 9.8vw;
  animation: ease 200s alternate infinite;
  pointer-events: none;
}
.firefly::before, .firefly::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform-origin: -10vw;
}
.firefly::before {
  background: black;
  opacity: 0.4;
  animation: drift ease alternate infinite;
}
.firefly::after {
  background: white;
  opacity: 0;
  box-shadow: 0 0 0vw 0vw yellow;
  animation: drift ease alternate infinite, flash ease infinite;
}

.firefly:nth-child(1) {
  animation-name: move1;
}
.firefly:nth-child(1)::before {
  animation-duration: 13s;
}
.firefly:nth-child(1)::after {
  animation-duration: 13s, 8171ms;
  animation-delay: 0ms, 3723ms;
}

@keyframes move1 {
  0% {
    transform: translateX(5vw) translateY(-1vh) scale(0.87);
  }
  3.7037037037% {
    transform: translateX(28vw) translateY(8vh) scale(0.93);
  }
  7.4074074074% {
    transform: translateX(-1vw) translateY(-28vh) scale(0.26);
  }
  11.1111111111% {
    transform: translateX(21vw) translateY(48vh) scale(0.88);
  }
  14.8148148148% {
    transform: translateX(23vw) translateY(-29vh) scale(0.37);
  }
  18.5185185185% {
    transform: translateX(-12vw) translateY(-21vh) scale(0.78);
  }
  22.2222222222% {
    transform: translateX(44vw) translateY(-6vh) scale(0.6);
  }
  25.9259259259% {
    transform: translateX(-45vw) translateY(-5vh) scale(0.89);
  }
  29.6296296296% {
    transform: translateX(-13vw) translateY(33vh) scale(0.94);
  }
  33.3333333333% {
    transform: translateX(21vw) translateY(-7vh) scale(0.34);
  }
  37.037037037% {
    transform: translateX(-23vw) translateY(8vh) scale(0.86);
  }
  40.7407407407% {
    transform: translateX(29vw) translateY(-39vh) scale(0.83);
  }
  44.4444444444% {
    transform: translateX(3vw) translateY(42vh) scale(0.9);
  }
  48.1481481481% {
    transform: translateX(-5vw) translateY(-13vh) scale(0.57);
  }
  51.8518518519% {
    transform: translateX(-39vw) translateY(-43vh) scale(0.9);
  }
  55.5555555556% {
    transform: translateX(-40vw) translateY(-10vh) scale(0.97);
  }
  59.2592592593% {
    transform: translateX(-41vw) translateY(-38vh) scale(0.72);
  }
  62.962962963% {
    transform: translateX(22vw) translateY(5vh) scale(0.52);
  }
  66.6666666667% {
    transform: translateX(-32vw) translateY(41vh) scale(0.82);
  }
  70.3703703704% {
    transform: translateX(10vw) translateY(14vh) scale(0.52);
  }
  74.0740740741% {
    transform: translateX(29vw) translateY(-16vh) scale(0.27);
  }
  77.7777777778% {
    transform: translateX(41vw) translateY(-33vh) scale(0.82);
  }
  81.4814814815% {
    transform: translateX(-4vw) translateY(-42vh) scale(0.44);
  }
  85.1851851852% {
    transform: translateX(-40vw) translateY(-28vh) scale(0.99);
  }
  88.8888888889% {
    transform: translateX(26vw) translateY(0vh) scale(0.76);
  }
  92.5925925926% {
    transform: translateX(6vw) translateY(39vh) scale(0.49);
  }
  96.2962962963% {
    transform: translateX(13vw) translateY(-39vh) scale(0.31);
  }
  100% {
    transform: translateX(-35vw) translateY(-29vh) scale(0.41);
  }
}
.firefly:nth-child(2) {
  animation-name: move2;
}
.firefly:nth-child(2)::before {
  animation-duration: 18s;
}
.firefly:nth-child(2)::after {
  animation-duration: 18s, 10043ms;
  animation-delay: 0ms, 3918ms;
}

@keyframes move2 {
  0% {
    transform: translateX(21vw) translateY(-4vh) scale(0.53);
  }
  5.2631578947% {
    transform: translateX(-27vw) translateY(-46vh) scale(0.62);
  }
  10.5263157895% {
    transform: translateX(-21vw) translateY(-36vh) scale(0.62);
  }
  15.7894736842% {
    transform: translateX(-35vw) translateY(-47vh) scale(0.55);
  }
  21.0526315789% {
    transform: translateX(-17vw) translateY(-24vh) scale(0.96);
  }
  26.3157894737% {
    transform: translateX(19vw) translateY(-32vh) scale(0.96);
  }
  31.5789473684% {
    transform: translateX(32vw) translateY(-44vh) scale(0.81);
  }
  36.8421052632% {
    transform: translateX(-19vw) translateY(-39vh) scale(0.57);
  }
  42.1052631579% {
    transform: translateX(46vw) translateY(45vh) scale(0.47);
  }
  47.3684210526% {
    transform: translateX(-16vw) translateY(7vh) scale(0.37);
  }
  52.6315789474% {
    transform: translateX(-8vw) translateY(14vh) scale(0.57);
  }
  57.8947368421% {
    transform: translateX(47vw) translateY(-39vh) scale(0.85);
  }
  63.1578947368% {
    transform: translateX(1vw) translateY(-6vh) scale(0.54);
  }
  68.4210526316% {
    transform: translateX(1vw) translateY(10vh) scale(0.39);
  }
  73.6842105263% {
    transform: translateX(46vw) translateY(42vh) scale(0.99);
  }
  78.9473684211% {
    transform: translateX(24vw) translateY(-1vh) scale(0.48);
  }
  84.2105263158% {
    transform: translateX(7vw) translateY(5vh) scale(0.86);
  }
  89.4736842105% {
    transform: translateX(-24vw) translateY(-32vh) scale(0.93);
  }
  94.7368421053% {
    transform: translateX(5vw) translateY(43vh) scale(0.49);
  }
  100% {
    transform: translateX(24vw) translateY(-21vh) scale(0.83);
  }
}
.firefly:nth-child(3) {
  animation-name: move3;
}
.firefly:nth-child(3)::before {
  animation-duration: 12s;
}
.firefly:nth-child(3)::after {
  animation-duration: 12s, 5280ms;
  animation-delay: 0ms, 7207ms;
}

@keyframes move3 {
  0% {
    transform: translateX(-25vw) translateY(44vh) scale(0.33);
  }
  4% {
    transform: translateX(17vw) translateY(25vh) scale(0.8);
  }
  8% {
    transform: translateX(9vw) translateY(46vh) scale(0.58);
  }
  12% {
    transform: translateX(-22vw) translateY(18vh) scale(0.54);
  }
  16% {
    transform: translateX(30vw) translateY(-22vh) scale(0.51);
  }
  20% {
    transform: translateX(13vw) translateY(12vh) scale(0.49);
  }
  24% {
    transform: translateX(-18vw) translateY(-20vh) scale(0.49);
  }
  28% {
    transform: translateX(35vw) translateY(14vh) scale(0.65);
  }
  32% {
    transform: translateX(-9vw) translateY(-3vh) scale(0.98);
  }
  36% {
    transform: translateX(20vw) translateY(-42vh) scale(0.34);
  }
  40% {
    transform: translateX(-4vw) translateY(43vh) scale(0.71);
  }
  44% {
    transform: translateX(-9vw) translateY(-28vh) scale(0.99);
  }
  48% {
    transform: translateX(1vw) translateY(-35vh) scale(0.93);
  }
  52% {
    transform: translateX(17vw) translateY(48vh) scale(0.29);
  }
  56% {
    transform: translateX(-41vw) translateY(34vh) scale(0.47);
  }
  60% {
    transform: translateX(38vw) translateY(-5vh) scale(0.54);
  }
  64% {
    transform: translateX(-21vw) translateY(-39vh) scale(0.36);
  }
  68% {
    transform: translateX(-27vw) translateY(28vh) scale(0.5);
  }
  72% {
    transform: translateX(20vw) translateY(41vh) scale(0.63);
  }
  76% {
    transform: translateX(-2vw) translateY(43vh) scale(0.29);
  }
  80% {
    transform: translateX(-40vw) translateY(-47vh) scale(0.45);
  }
  84% {
    transform: translateX(-41vw) translateY(5vh) scale(0.3);
  }
  88% {
    transform: translateX(0vw) translateY(16vh) scale(0.48);
  }
  92% {
    transform: translateX(-13vw) translateY(35vh) scale(0.68);
  }
  96% {
    transform: translateX(46vw) translateY(25vh) scale(0.31);
  }
  100% {
    transform: translateX(38vw) translateY(-7vh) scale(0.73);
  }
}
.firefly:nth-child(4) {
  animation-name: move4;
}
.firefly:nth-child(4)::before {
  animation-duration: 9s;
}
.firefly:nth-child(4)::after {
  animation-duration: 9s, 9653ms;
  animation-delay: 0ms, 6845ms;
}

@keyframes move4 {
  0% {
    transform: translateX(22vw) translateY(30vh) scale(0.43);
  }
  3.5714285714% {
    transform: translateX(46vw) translateY(37vh) scale(0.56);
  }
  7.1428571429% {
    transform: translateX(7vw) translateY(42vh) scale(0.66);
  }
  10.7142857143% {
    transform: translateX(-4vw) translateY(-1vh) scale(0.88);
  }
  14.2857142857% {
    transform: translateX(50vw) translateY(50vh) scale(0.4);
  }
  17.8571428571% {
    transform: translateX(-45vw) translateY(34vh) scale(0.28);
  }
  21.4285714286% {
    transform: translateX(2vw) translateY(-9vh) scale(1);
  }
  25% {
    transform: translateX(-23vw) translateY(40vh) scale(0.27);
  }
  28.5714285714% {
    transform: translateX(-7vw) translateY(-11vh) scale(0.92);
  }
  32.1428571429% {
    transform: translateX(7vw) translateY(-19vh) scale(0.29);
  }
  35.7142857143% {
    transform: translateX(-14vw) translateY(5vh) scale(1);
  }
  39.2857142857% {
    transform: translateX(29vw) translateY(45vh) scale(0.32);
  }
  42.8571428571% {
    transform: translateX(-23vw) translateY(-23vh) scale(0.41);
  }
  46.4285714286% {
    transform: translateX(19vw) translateY(30vh) scale(0.59);
  }
  50% {
    transform: translateX(7vw) translateY(-26vh) scale(0.92);
  }
  53.5714285714% {
    transform: translateX(-44vw) translateY(-17vh) scale(0.44);
  }
  57.1428571429% {
    transform: translateX(33vw) translateY(17vh) scale(0.57);
  }
  60.7142857143% {
    transform: translateX(-34vw) translateY(20vh) scale(0.86);
  }
  64.2857142857% {
    transform: translateX(-30vw) translateY(42vh) scale(0.78);
  }
  67.8571428571% {
    transform: translateX(-9vw) translateY(-36vh) scale(0.85);
  }
  71.4285714286% {
    transform: translateX(22vw) translateY(20vh) scale(0.89);
  }
  75% {
    transform: translateX(26vw) translateY(30vh) scale(0.74);
  }
  78.5714285714% {
    transform: translateX(2vw) translateY(39vh) scale(0.99);
  }
  82.1428571429% {
    transform: translateX(-26vw) translateY(42vh) scale(0.85);
  }
  85.7142857143% {
    transform: translateX(36vw) translateY(-5vh) scale(0.59);
  }
  89.2857142857% {
    transform: translateX(35vw) translateY(23vh) scale(0.81);
  }
  92.8571428571% {
    transform: translateX(15vw) translateY(31vh) scale(0.6);
  }
  96.4285714286% {
    transform: translateX(-3vw) translateY(-38vh) scale(0.98);
  }
  100% {
    transform: translateX(46vw) translateY(33vh) scale(0.88);
  }
}
.firefly:nth-child(5) {
  animation-name: move5;
}
.firefly:nth-child(5)::before {
  animation-duration: 11s;
}
.firefly:nth-child(5)::after {
  animation-duration: 11s, 8986ms;
  animation-delay: 0ms, 7720ms;
}

@keyframes move5 {
  0% {
    transform: translateX(10vw) translateY(25vh) scale(0.99);
  }
  4.7619047619% {
    transform: translateX(-15vw) translateY(1vh) scale(0.84);
  }
  9.5238095238% {
    transform: translateX(12vw) translateY(-36vh) scale(0.86);
  }
  14.2857142857% {
    transform: translateX(50vw) translateY(35vh) scale(0.26);
  }
  19.0476190476% {
    transform: translateX(5vw) translateY(-35vh) scale(0.51);
  }
  23.8095238095% {
    transform: translateX(-41vw) translateY(28vh) scale(0.44);
  }
  28.5714285714% {
    transform: translateX(-25vw) translateY(1vh) scale(0.36);
  }
  33.3333333333% {
    transform: translateX(-19vw) translateY(15vh) scale(0.98);
  }
  38.0952380952% {
    transform: translateX(13vw) translateY(11vh) scale(0.66);
  }
  42.8571428571% {
    transform: translateX(2vw) translateY(8vh) scale(0.79);
  }
  47.619047619% {
    transform: translateX(28vw) translateY(5vh) scale(0.4);
  }
  52.380952381% {
    transform: translateX(37vw) translateY(14vh) scale(0.4);
  }
  57.1428571429% {
    transform: translateX(45vw) translateY(49vh) scale(0.64);
  }
  61.9047619048% {
    transform: translateX(42vw) translateY(0vh) scale(0.93);
  }
  66.6666666667% {
    transform: translateX(-16vw) translateY(48vh) scale(0.82);
  }
  71.4285714286% {
    transform: translateX(16vw) translateY(42vh) scale(0.49);
  }
  76.1904761905% {
    transform: translateX(13vw) translateY(47vh) scale(0.87);
  }
  80.9523809524% {
    transform: translateX(25vw) translateY(35vh) scale(0.8);
  }
  85.7142857143% {
    transform: translateX(39vw) translateY(-34vh) scale(0.55);
  }
  90.4761904762% {
    transform: translateX(22vw) translateY(2vh) scale(0.26);
  }
  95.2380952381% {
    transform: translateX(21vw) translateY(49vh) scale(0.54);
  }
  100% {
    transform: translateX(-45vw) translateY(-26vh) scale(0.45);
  }
}
.firefly:nth-child(6) {
  animation-name: move6;
}
.firefly:nth-child(6)::before {
  animation-duration: 11s;
}
.firefly:nth-child(6)::after {
  animation-duration: 11s, 6338ms;
  animation-delay: 0ms, 7039ms;
}

@keyframes move6 {
  0% {
    transform: translateX(-48vw) translateY(26vh) scale(0.94);
  }
  4.347826087% {
    transform: translateX(26vw) translateY(-27vh) scale(0.69);
  }
  8.6956521739% {
    transform: translateX(6vw) translateY(-9vh) scale(0.63);
  }
  13.0434782609% {
    transform: translateX(25vw) translateY(-46vh) scale(0.64);
  }
  17.3913043478% {
    transform: translateX(-31vw) translateY(17vh) scale(0.65);
  }
  21.7391304348% {
    transform: translateX(11vw) translateY(-4vh) scale(0.52);
  }
  26.0869565217% {
    transform: translateX(11vw) translateY(29vh) scale(0.36);
  }
  30.4347826087% {
    transform: translateX(-48vw) translateY(11vh) scale(0.67);
  }
  34.7826086957% {
    transform: translateX(39vw) translateY(-3vh) scale(0.6);
  }
  39.1304347826% {
    transform: translateX(16vw) translateY(-30vh) scale(0.8);
  }
  43.4782608696% {
    transform: translateX(0vw) translateY(50vh) scale(0.39);
  }
  47.8260869565% {
    transform: translateX(48vw) translateY(27vh) scale(0.9);
  }
  52.1739130435% {
    transform: translateX(-21vw) translateY(-1vh) scale(0.97);
  }
  56.5217391304% {
    transform: translateX(34vw) translateY(-40vh) scale(0.46);
  }
  60.8695652174% {
    transform: translateX(25vw) translateY(-43vh) scale(0.98);
  }
  65.2173913043% {
    transform: translateX(21vw) translateY(50vh) scale(0.79);
  }
  69.5652173913% {
    transform: translateX(-18vw) translateY(-34vh) scale(0.92);
  }
  73.9130434783% {
    transform: translateX(-30vw) translateY(43vh) scale(0.31);
  }
  78.2608695652% {
    transform: translateX(-17vw) translateY(-28vh) scale(0.78);
  }
  82.6086956522% {
    transform: translateX(-26vw) translateY(48vh) scale(0.36);
  }
  86.9565217391% {
    transform: translateX(-37vw) translateY(-29vh) scale(0.3);
  }
  91.3043478261% {
    transform: translateX(-36vw) translateY(26vh) scale(0.7);
  }
  95.652173913% {
    transform: translateX(-11vw) translateY(-34vh) scale(0.87);
  }
  100% {
    transform: translateX(17vw) translateY(22vh) scale(0.48);
  }
}
.firefly:nth-child(7) {
  animation-name: move7;
}
.firefly:nth-child(7)::before {
  animation-duration: 14s;
}
.firefly:nth-child(7)::after {
  animation-duration: 14s, 5055ms;
  animation-delay: 0ms, 7589ms;
}

@keyframes move7 {
  0% {
    transform: translateX(-40vw) translateY(-49vh) scale(0.69);
  }
  3.7037037037% {
    transform: translateX(-10vw) translateY(18vh) scale(0.85);
  }
  7.4074074074% {
    transform: translateX(-46vw) translateY(17vh) scale(0.56);
  }
  11.1111111111% {
    transform: translateX(-34vw) translateY(-29vh) scale(0.73);
  }
  14.8148148148% {
    transform: translateX(9vw) translateY(-6vh) scale(0.52);
  }
  18.5185185185% {
    transform: translateX(-10vw) translateY(34vh) scale(0.82);
  }
  22.2222222222% {
    transform: translateX(17vw) translateY(1vh) scale(0.44);
  }
  25.9259259259% {
    transform: translateX(22vw) translateY(-17vh) scale(0.61);
  }
  29.6296296296% {
    transform: translateX(-43vw) translateY(-7vh) scale(1);
  }
  33.3333333333% {
    transform: translateX(-1vw) translateY(-5vh) scale(0.92);
  }
  37.037037037% {
    transform: translateX(-3vw) translateY(33vh) scale(0.29);
  }
  40.7407407407% {
    transform: translateX(45vw) translateY(-24vh) scale(0.53);
  }
  44.4444444444% {
    transform: translateX(-28vw) translateY(-21vh) scale(0.35);
  }
  48.1481481481% {
    transform: translateX(24vw) translateY(5vh) scale(0.53);
  }
  51.8518518519% {
    transform: translateX(4vw) translateY(-41vh) scale(0.62);
  }
  55.5555555556% {
    transform: translateX(-36vw) translateY(-18vh) scale(0.93);
  }
  59.2592592593% {
    transform: translateX(36vw) translateY(-4vh) scale(0.6);
  }
  62.962962963% {
    transform: translateX(-43vw) translateY(23vh) scale(0.94);
  }
  66.6666666667% {
    transform: translateX(-12vw) translateY(13vh) scale(0.97);
  }
  70.3703703704% {
    transform: translateX(20vw) translateY(40vh) scale(0.69);
  }
  74.0740740741% {
    transform: translateX(-47vw) translateY(5vh) scale(1);
  }
  77.7777777778% {
    transform: translateX(-22vw) translateY(46vh) scale(0.59);
  }
  81.4814814815% {
    transform: translateX(22vw) translateY(13vh) scale(0.53);
  }
  85.1851851852% {
    transform: translateX(43vw) translateY(-17vh) scale(0.81);
  }
  88.8888888889% {
    transform: translateX(-39vw) translateY(46vh) scale(0.55);
  }
  92.5925925926% {
    transform: translateX(-30vw) translateY(30vh) scale(0.91);
  }
  96.2962962963% {
    transform: translateX(26vw) translateY(-46vh) scale(0.76);
  }
  100% {
    transform: translateX(37vw) translateY(-37vh) scale(0.65);
  }
}
.firefly:nth-child(8) {
  animation-name: move8;
}
.firefly:nth-child(8)::before {
  animation-duration: 9s;
}
.firefly:nth-child(8)::after {
  animation-duration: 9s, 9152ms;
  animation-delay: 0ms, 2098ms;
}

@keyframes move8 {
  0% {
    transform: translateX(-37vw) translateY(11vh) scale(0.37);
  }
  5.5555555556% {
    transform: translateX(-37vw) translateY(-13vh) scale(0.68);
  }
  11.1111111111% {
    transform: translateX(-9vw) translateY(-47vh) scale(0.59);
  }
  16.6666666667% {
    transform: translateX(44vw) translateY(43vh) scale(0.55);
  }
  22.2222222222% {
    transform: translateX(-12vw) translateY(-29vh) scale(0.44);
  }
  27.7777777778% {
    transform: translateX(39vw) translateY(29vh) scale(0.53);
  }
  33.3333333333% {
    transform: translateX(31vw) translateY(4vh) scale(0.46);
  }
  38.8888888889% {
    transform: translateX(-33vw) translateY(23vh) scale(0.93);
  }
  44.4444444444% {
    transform: translateX(36vw) translateY(-33vh) scale(0.74);
  }
  50% {
    transform: translateX(36vw) translateY(-20vh) scale(0.92);
  }
  55.5555555556% {
    transform: translateX(24vw) translateY(-14vh) scale(0.93);
  }
  61.1111111111% {
    transform: translateX(25vw) translateY(-26vh) scale(0.31);
  }
  66.6666666667% {
    transform: translateX(-49vw) translateY(12vh) scale(0.29);
  }
  72.2222222222% {
    transform: translateX(10vw) translateY(12vh) scale(0.85);
  }
  77.7777777778% {
    transform: translateX(-12vw) translateY(-45vh) scale(0.8);
  }
  83.3333333333% {
    transform: translateX(32vw) translateY(38vh) scale(0.88);
  }
  88.8888888889% {
    transform: translateX(-3vw) translateY(-34vh) scale(0.33);
  }
  94.4444444444% {
    transform: translateX(50vw) translateY(-4vh) scale(0.35);
  }
  100% {
    transform: translateX(-17vw) translateY(43vh) scale(0.29);
  }
}
.firefly:nth-child(9) {
  animation-name: move9;
}
.firefly:nth-child(9)::before {
  animation-duration: 11s;
}
.firefly:nth-child(9)::after {
  animation-duration: 11s, 7189ms;
  animation-delay: 0ms, 4218ms;
}

@keyframes move9 {
  0% {
    transform: translateX(38vw) translateY(4vh) scale(0.8);
  }
  5% {
    transform: translateX(-15vw) translateY(-22vh) scale(0.92);
  }
  10% {
    transform: translateX(-11vw) translateY(36vh) scale(0.58);
  }
  15% {
    transform: translateX(39vw) translateY(22vh) scale(0.37);
  }
  20% {
    transform: translateX(-47vw) translateY(39vh) scale(0.48);
  }
  25% {
    transform: translateX(13vw) translateY(-35vh) scale(0.69);
  }
  30% {
    transform: translateX(0vw) translateY(-35vh) scale(0.74);
  }
  35% {
    transform: translateX(-1vw) translateY(2vh) scale(0.6);
  }
  40% {
    transform: translateX(-35vw) translateY(32vh) scale(0.38);
  }
  45% {
    transform: translateX(-17vw) translateY(-16vh) scale(0.8);
  }
  50% {
    transform: translateX(-31vw) translateY(-44vh) scale(0.44);
  }
  55% {
    transform: translateX(46vw) translateY(-22vh) scale(0.83);
  }
  60% {
    transform: translateX(-25vw) translateY(30vh) scale(0.4);
  }
  65% {
    transform: translateX(25vw) translateY(-43vh) scale(0.82);
  }
  70% {
    transform: translateX(5vw) translateY(-32vh) scale(0.61);
  }
  75% {
    transform: translateX(50vw) translateY(44vh) scale(0.48);
  }
  80% {
    transform: translateX(37vw) translateY(14vh) scale(0.91);
  }
  85% {
    transform: translateX(15vw) translateY(8vh) scale(0.68);
  }
  90% {
    transform: translateX(-9vw) translateY(-13vh) scale(1);
  }
  95% {
    transform: translateX(-7vw) translateY(36vh) scale(0.93);
  }
  100% {
    transform: translateX(41vw) translateY(-3vh) scale(0.56);
  }
}
.firefly:nth-child(10) {
  animation-name: move10;
}
.firefly:nth-child(10)::before {
  animation-duration: 10s;
}
.firefly:nth-child(10)::after {
  animation-duration: 10s, 7732ms;
  animation-delay: 0ms, 5366ms;
}

@keyframes move10 {
  0% {
    transform: translateX(14vw) translateY(-28vh) scale(0.64);
  }
  4.347826087% {
    transform: translateX(-42vw) translateY(38vh) scale(0.27);
  }
  8.6956521739% {
    transform: translateX(13vw) translateY(13vh) scale(0.86);
  }
  13.0434782609% {
    transform: translateX(-25vw) translateY(17vh) scale(0.42);
  }
  17.3913043478% {
    transform: translateX(-44vw) translateY(-33vh) scale(0.66);
  }
  21.7391304348% {
    transform: translateX(-22vw) translateY(18vh) scale(0.89);
  }
  26.0869565217% {
    transform: translateX(-48vw) translateY(-47vh) scale(0.81);
  }
  30.4347826087% {
    transform: translateX(-11vw) translateY(-36vh) scale(0.62);
  }
  34.7826086957% {
    transform: translateX(-33vw) translateY(14vh) scale(0.97);
  }
  39.1304347826% {
    transform: translateX(-15vw) translateY(-30vh) scale(0.39);
  }
  43.4782608696% {
    transform: translateX(23vw) translateY(-14vh) scale(0.61);
  }
  47.8260869565% {
    transform: translateX(-49vw) translateY(-6vh) scale(0.87);
  }
  52.1739130435% {
    transform: translateX(-3vw) translateY(14vh) scale(0.92);
  }
  56.5217391304% {
    transform: translateX(-28vw) translateY(14vh) scale(0.76);
  }
  60.8695652174% {
    transform: translateX(1vw) translateY(-47vh) scale(0.36);
  }
  65.2173913043% {
    transform: translateX(-26vw) translateY(-45vh) scale(0.43);
  }
  69.5652173913% {
    transform: translateX(-37vw) translateY(-10vh) scale(0.78);
  }
  73.9130434783% {
    transform: translateX(-33vw) translateY(-1vh) scale(0.79);
  }
  78.2608695652% {
    transform: translateX(38vw) translateY(-2vh) scale(0.75);
  }
  82.6086956522% {
    transform: translateX(-43vw) translateY(-47vh) scale(0.58);
  }
  86.9565217391% {
    transform: translateX(-48vw) translateY(27vh) scale(0.41);
  }
  91.3043478261% {
    transform: translateX(49vw) translateY(-37vh) scale(0.43);
  }
  95.652173913% {
    transform: translateX(14vw) translateY(-44vh) scale(0.94);
  }
  100% {
    transform: translateX(43vw) translateY(20vh) scale(0.64);
  }
}
.firefly:nth-child(11) {
  animation-name: move11;
}
.firefly:nth-child(11)::before {
  animation-duration: 17s;
}
.firefly:nth-child(11)::after {
  animation-duration: 17s, 8841ms;
  animation-delay: 0ms, 1373ms;
}

@keyframes move11 {
  0% {
    transform: translateX(19vw) translateY(-21vh) scale(0.92);
  }
  4.5454545455% {
    transform: translateX(11vw) translateY(-14vh) scale(0.62);
  }
  9.0909090909% {
    transform: translateX(-12vw) translateY(-32vh) scale(0.96);
  }
  13.6363636364% {
    transform: translateX(4vw) translateY(33vh) scale(0.63);
  }
  18.1818181818% {
    transform: translateX(14vw) translateY(-25vh) scale(0.79);
  }
  22.7272727273% {
    transform: translateX(16vw) translateY(-15vh) scale(0.99);
  }
  27.2727272727% {
    transform: translateX(-17vw) translateY(-8vh) scale(0.33);
  }
  31.8181818182% {
    transform: translateX(10vw) translateY(-5vh) scale(0.61);
  }
  36.3636363636% {
    transform: translateX(23vw) translateY(-30vh) scale(0.9);
  }
  40.9090909091% {
    transform: translateX(-4vw) translateY(-16vh) scale(0.85);
  }
  45.4545454545% {
    transform: translateX(35vw) translateY(-41vh) scale(0.46);
  }
  50% {
    transform: translateX(19vw) translateY(-20vh) scale(0.32);
  }
  54.5454545455% {
    transform: translateX(19vw) translateY(4vh) scale(0.59);
  }
  59.0909090909% {
    transform: translateX(-24vw) translateY(-29vh) scale(0.65);
  }
  63.6363636364% {
    transform: translateX(-48vw) translateY(7vh) scale(0.88);
  }
  68.1818181818% {
    transform: translateX(-44vw) translateY(50vh) scale(0.5);
  }
  72.7272727273% {
    transform: translateX(29vw) translateY(5vh) scale(0.63);
  }
  77.2727272727% {
    transform: translateX(-46vw) translateY(-21vh) scale(0.73);
  }
  81.8181818182% {
    transform: translateX(7vw) translateY(12vh) scale(0.51);
  }
  86.3636363636% {
    transform: translateX(35vw) translateY(-48vh) scale(0.5);
  }
  90.9090909091% {
    transform: translateX(-43vw) translateY(-8vh) scale(0.95);
  }
  95.4545454545% {
    transform: translateX(13vw) translateY(13vh) scale(0.32);
  }
  100% {
    transform: translateX(-21vw) translateY(-26vh) scale(0.92);
  }
}
.firefly:nth-child(12) {
  animation-name: move12;
}
.firefly:nth-child(12)::before {
  animation-duration: 13s;
}
.firefly:nth-child(12)::after {
  animation-duration: 13s, 6171ms;
  animation-delay: 0ms, 8132ms;
}

@keyframes move12 {
  0% {
    transform: translateX(-48vw) translateY(9vh) scale(0.89);
  }
  4.1666666667% {
    transform: translateX(-39vw) translateY(13vh) scale(0.73);
  }
  8.3333333333% {
    transform: translateX(36vw) translateY(-29vh) scale(0.66);
  }
  12.5% {
    transform: translateX(18vw) translateY(-22vh) scale(0.63);
  }
  16.6666666667% {
    transform: translateX(-19vw) translateY(-30vh) scale(0.85);
  }
  20.8333333333% {
    transform: translateX(-25vw) translateY(-8vh) scale(0.48);
  }
  25% {
    transform: translateX(-25vw) translateY(29vh) scale(0.92);
  }
  29.1666666667% {
    transform: translateX(-30vw) translateY(42vh) scale(0.27);
  }
  33.3333333333% {
    transform: translateX(-25vw) translateY(11vh) scale(0.95);
  }
  37.5% {
    transform: translateX(-35vw) translateY(41vh) scale(0.96);
  }
  41.6666666667% {
    transform: translateX(-37vw) translateY(28vh) scale(0.9);
  }
  45.8333333333% {
    transform: translateX(23vw) translateY(49vh) scale(0.76);
  }
  50% {
    transform: translateX(-30vw) translateY(-5vh) scale(0.57);
  }
  54.1666666667% {
    transform: translateX(3vw) translateY(-25vh) scale(0.55);
  }
  58.3333333333% {
    transform: translateX(50vw) translateY(49vh) scale(0.91);
  }
  62.5% {
    transform: translateX(5vw) translateY(-2vh) scale(0.6);
  }
  66.6666666667% {
    transform: translateX(20vw) translateY(-39vh) scale(0.4);
  }
  70.8333333333% {
    transform: translateX(-35vw) translateY(35vh) scale(0.3);
  }
  75% {
    transform: translateX(46vw) translateY(17vh) scale(0.97);
  }
  79.1666666667% {
    transform: translateX(49vw) translateY(-36vh) scale(0.89);
  }
  83.3333333333% {
    transform: translateX(-6vw) translateY(15vh) scale(0.81);
  }
  87.5% {
    transform: translateX(38vw) translateY(-23vh) scale(0.44);
  }
  91.6666666667% {
    transform: translateX(-49vw) translateY(-26vh) scale(0.31);
  }
  95.8333333333% {
    transform: translateX(-2vw) translateY(-43vh) scale(0.79);
  }
  100% {
    transform: translateX(-36vw) translateY(16vh) scale(0.97);
  }
}
.firefly:nth-child(13) {
  animation-name: move13;
}
.firefly:nth-child(13)::before {
  animation-duration: 12s;
}
.firefly:nth-child(13)::after {
  animation-duration: 12s, 7131ms;
  animation-delay: 0ms, 4355ms;
}

@keyframes move13 {
  0% {
    transform: translateX(-29vw) translateY(27vh) scale(0.76);
  }
  4.7619047619% {
    transform: translateX(-31vw) translateY(8vh) scale(0.26);
  }
  9.5238095238% {
    transform: translateX(49vw) translateY(-13vh) scale(0.26);
  }
  14.2857142857% {
    transform: translateX(-34vw) translateY(7vh) scale(0.58);
  }
  19.0476190476% {
    transform: translateX(-15vw) translateY(-26vh) scale(0.51);
  }
  23.8095238095% {
    transform: translateX(18vw) translateY(26vh) scale(0.89);
  }
  28.5714285714% {
    transform: translateX(-34vw) translateY(-20vh) scale(0.37);
  }
  33.3333333333% {
    transform: translateX(-10vw) translateY(-15vh) scale(0.6);
  }
  38.0952380952% {
    transform: translateX(-16vw) translateY(17vh) scale(0.92);
  }
  42.8571428571% {
    transform: translateX(-1vw) translateY(46vh) scale(0.91);
  }
  47.619047619% {
    transform: translateX(26vw) translateY(15vh) scale(0.7);
  }
  52.380952381% {
    transform: translateX(3vw) translateY(-45vh) scale(0.66);
  }
  57.1428571429% {
    transform: translateX(-10vw) translateY(31vh) scale(0.91);
  }
  61.9047619048% {
    transform: translateX(48vw) translateY(-18vh) scale(0.96);
  }
  66.6666666667% {
    transform: translateX(16vw) translateY(-19vh) scale(0.95);
  }
  71.4285714286% {
    transform: translateX(-20vw) translateY(5vh) scale(0.83);
  }
  76.1904761905% {
    transform: translateX(15vw) translateY(14vh) scale(0.89);
  }
  80.9523809524% {
    transform: translateX(-43vw) translateY(25vh) scale(0.83);
  }
  85.7142857143% {
    transform: translateX(40vw) translateY(45vh) scale(0.87);
  }
  90.4761904762% {
    transform: translateX(1vw) translateY(-21vh) scale(0.49);
  }
  95.2380952381% {
    transform: translateX(-7vw) translateY(-33vh) scale(0.58);
  }
  100% {
    transform: translateX(25vw) translateY(-1vh) scale(0.94);
  }
}
.firefly:nth-child(14) {
  animation-name: move14;
}
.firefly:nth-child(14)::before {
  animation-duration: 17s;
}
.firefly:nth-child(14)::after {
  animation-duration: 17s, 9805ms;
  animation-delay: 0ms, 7303ms;
}

@keyframes move14 {
  0% {
    transform: translateX(-14vw) translateY(-36vh) scale(0.3);
  }
  5.5555555556% {
    transform: translateX(30vw) translateY(30vh) scale(0.97);
  }
  11.1111111111% {
    transform: translateX(-35vw) translateY(-12vh) scale(0.4);
  }
  16.6666666667% {
    transform: translateX(25vw) translateY(-26vh) scale(0.97);
  }
  22.2222222222% {
    transform: translateX(-31vw) translateY(-6vh) scale(0.8);
  }
  27.7777777778% {
    transform: translateX(-39vw) translateY(-31vh) scale(0.26);
  }
  33.3333333333% {
    transform: translateX(19vw) translateY(42vh) scale(0.41);
  }
  38.8888888889% {
    transform: translateX(-16vw) translateY(27vh) scale(0.35);
  }
  44.4444444444% {
    transform: translateX(-44vw) translateY(40vh) scale(0.68);
  }
  50% {
    transform: translateX(19vw) translateY(-21vh) scale(0.42);
  }
  55.5555555556% {
    transform: translateX(16vw) translateY(30vh) scale(0.6);
  }
  61.1111111111% {
    transform: translateX(-41vw) translateY(-14vh) scale(0.96);
  }
  66.6666666667% {
    transform: translateX(14vw) translateY(33vh) scale(0.39);
  }
  72.2222222222% {
    transform: translateX(-30vw) translateY(-7vh) scale(0.74);
  }
  77.7777777778% {
    transform: translateX(-9vw) translateY(33vh) scale(0.96);
  }
  83.3333333333% {
    transform: translateX(43vw) translateY(32vh) scale(0.91);
  }
  88.8888888889% {
    transform: translateX(-11vw) translateY(32vh) scale(0.48);
  }
  94.4444444444% {
    transform: translateX(-33vw) translateY(48vh) scale(0.35);
  }
  100% {
    transform: translateX(-36vw) translateY(-44vh) scale(0.7);
  }
}
.firefly:nth-child(15) {
  animation-name: move15;
}
.firefly:nth-child(15)::before {
  animation-duration: 17s;
}
.firefly:nth-child(15)::after {
  animation-duration: 17s, 10846ms;
  animation-delay: 0ms, 1497ms;
}

@keyframes move15 {
  0% {
    transform: translateX(-2vw) translateY(30vh) scale(0.84);
  }
  5.2631578947% {
    transform: translateX(47vw) translateY(13vh) scale(0.36);
  }
  10.5263157895% {
    transform: translateX(-25vw) translateY(27vh) scale(0.47);
  }
  15.7894736842% {
    transform: translateX(-34vw) translateY(45vh) scale(0.29);
  }
  21.0526315789% {
    transform: translateX(-11vw) translateY(-31vh) scale(0.83);
  }
  26.3157894737% {
    transform: translateX(-35vw) translateY(-20vh) scale(0.91);
  }
  31.5789473684% {
    transform: translateX(12vw) translateY(-47vh) scale(0.5);
  }
  36.8421052632% {
    transform: translateX(33vw) translateY(-2vh) scale(0.58);
  }
  42.1052631579% {
    transform: translateX(-30vw) translateY(-30vh) scale(0.41);
  }
  47.3684210526% {
    transform: translateX(-49vw) translateY(30vh) scale(0.9);
  }
  52.6315789474% {
    transform: translateX(-37vw) translateY(44vh) scale(0.91);
  }
  57.8947368421% {
    transform: translateX(7vw) translateY(20vh) scale(0.91);
  }
  63.1578947368% {
    transform: translateX(-47vw) translateY(37vh) scale(0.42);
  }
  68.4210526316% {
    transform: translateX(48vw) translateY(-48vh) scale(0.4);
  }
  73.6842105263% {
    transform: translateX(26vw) translateY(38vh) scale(0.96);
  }
  78.9473684211% {
    transform: translateX(7vw) translateY(-30vh) scale(0.51);
  }
  84.2105263158% {
    transform: translateX(7vw) translateY(46vh) scale(0.59);
  }
  89.4736842105% {
    transform: translateX(-30vw) translateY(-30vh) scale(0.72);
  }
  94.7368421053% {
    transform: translateX(-18vw) translateY(22vh) scale(0.8);
  }
  100% {
    transform: translateX(32vw) translateY(-28vh) scale(0.73);
  }
}
@keyframes drift {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes flash {
  0%, 30%, 100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
  }
  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw yellow;
  }
}

`;

const MainWrapper = styled.div`
  position: relative;
  display: block;
  vertical-align: top;
  background: #1b1b1b;
  color: #fff;
  min-height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 50%;
  @media screen and (max-width: 770px) {
    width: 100%;
  }
  bottom: 0px;
  right: 0px;
  top: 0px;
  height: 100%;
  left: 0px;
  float: right;
`;

const Content = styled.section`
  text-align: center;
  transform: none;
  top: 150px;
  margin: auto;
  margin-top: 50px;
  padding-bottom: 30px;
  margin-bottom: 45px;
  width: 80%;
  max-width: 1300px;
  padding-top: 30px;
  @media screen and (max-width: 770px) {
    width: 100%;
  }

  & h1 {
    font-size: 40px;
    font-weight: 300;
    line-height: 1.5em;
  }

  & form {
    margin: auto;
  }

  & .form {
    padding: 20px;
  }

  & .form .fieldset:first-child {
    margin-top: 0;
  }

  & .form .fieldset {
    position: relative;
    margin: 20px 0;
  }

  & p {
    font-size: 16px;
    line-height: 1.5em;
    color: #fff;
  }

  & .form label.image-replace {
    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -7px;
  }

  & .form label {
    color: #666;
    font-size: 14px;
  }

  & .form input.has-border {
    border: 1px solid #d2d8d8;
  }

  & .form input.has-padding {
    padding: 12px 20px 12px 50px;
  }

  & .form input.full-width {
    width: 100%;
  }

  & .form input {
    margin: 0;
    border-radius: 3px;
    font-size: 16px;
  }

  & .form .error-message {
    display: inline-block;
    position: absolute;
    left: -5px;
    bottom: -35px;
    background: rgba(215, 102, 102, 0.9);
    padding: .8em;
    z-index: 2;
    color: #FFF;
    font-size: 13px;
    border-radius: 0.25em;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
  }

  & .form .error-message::after {
    content: '';
    position: absolute;
    left: 22px;
    bottom: 100%;
    height: 0;
    width: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid rgba(215, 102, 102, 0.9);
  }

  & .form label.image-replace {
    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -7px;
  }

  & .form input[type=password] {
    padding-right: 65px;
  }

  & .form .hide-password {
    display: inline-block;
    position: absolute;
    right: 0;
    padding: 6px 15px;
    border-left: 1px solid #d2d8d8;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 14px;
    color: #343642;
  }

  & a {
    text-decoration: none;
    transition: .3s ease;
  }

  & .form input[type="checkbox"] {
    margin-right: 5px;
  }

  & .form .fieldset:last-child {
    margin-bottom: 0;
  }

  & form input[type="submit"] {
    width: 100%;
  }

  & .form input[type=submit], .form .login-submit, .form .register-submit {
    text-align: center;
    padding: 16px 0;
    cursor: pointer;
    background: #298eea;
    color: #FFF;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 3px;
  }

  & .form input[type=submit]:hover, .form .login-submit:hover, .form input[type=submit]:focus {
    background: #2c97f9;
    outline: none;
  }

  & .btn-alt {
    font-size: 14px;
    display: inline-block;
    padding: 8px 20px;
    color: rgba(255,255,255,.7);
    border: 0px solid rgba(255,255,255,.7);
    border-radius: 3px;
    cursor: pointer;
  }

`;

const Register = (props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ref = searchParams.get('ref');
  const [registerForm, setRegisterForm] = useState(ref ? {ref} : {});
  const user = useSelector(redux => redux.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [disabled, setDisabled] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (registerForm.password !== registerForm.confirmPass) {
      Swal.fire('Error', 'The password fields do not match.', 'error');
      return;
    }
    if (!registerForm.email || !registerForm.name || !registerForm.password) {
      Swal.fire('Error', 'Please fill the form.', 'error');
      return;
    }
    setDisabled(true);
    const registered = await dispatch(registerUser(registerForm));
    if (registered) {
      history.push('/');
    }
    setDisabled(false);
  }

  useEffect(() => {
    if (user && user.username) {
      history.push('/')
    }
  },[user]);

  return (
    <>
    <Background />
    <FireFlyWrapper>
    <div class="firefly"></div>
<div class="firefly"></div>
<div class="firefly"></div>
<div class="firefly"></div>
<div class="firefly"></div>
<div class="firefly"></div>
<div class="firefly"></div>
<div class="firefly"></div>
<div class="firefly"></div>
<div class="firefly"></div>
<div class="firefly"></div>
<div class="firefly"></div>
<div class="firefly"></div>
<div class="firefly"></div>
<div class="firefly"></div>
</FireFlyWrapper>
    <MainWrapper>
      <Content>
        <h1>Sign Up</h1>
        <span>All fields are required.</span>
        <br />
        <form className="form" method="post">
        <p className="fieldset">
            <label className="image-replace username" htmlFor="signin-username"><i className="fas fa-user" /></label>
            <input
            className="full-width has-padding has-border"
            id="signin-username"
            name="name" type="text" required=""
            placeholder="Name"
            onChange={(e) => setRegisterForm({...registerForm, [e.target.name]: e.target.value})}/>
          </p>
          <p className="fieldset">
            <label className="image-replace username" htmlFor="signin-username"><i className="fas fa-user" /></label>
            <input
            className="full-width has-padding has-border"
            id="signin-username"
            name="email" type="text" required=""
            placeholder="Email"
            onChange={(e) => setRegisterForm({...registerForm, [e.target.name]: e.target.value})}/>
            <span className="error-message">Error message here!</span>
          </p>
          <p className="fieldset">
            <label className="image-replace password" htmlFor="signin-password1"><i className="fas fa-key" /></label>
            <input className="full-width has-padding has-border" id="signin-password1" name="password" type="password" required="" placeholder="Password (case sensitive)"
                        onChange={(e) => setRegisterForm({...registerForm, [e.target.name]: e.target.value})}/>
            <span className="error-message">Error</span>
          </p>
          <p className="fieldset">
            <label className="image-replace password" htmlFor="signin-password2"><i className="fas fa-key" /></label>
            <input className="full-width has-padding has-border" id="signin-password2" name="confirmPass" type="password" required="" placeholder="Repeat Password (case sensitive)"
                        onChange={(e) => setRegisterForm({...registerForm, [e.target.name]: e.target.value})}/>
            <span className="error-message">Error</span>
          </p>
          <ReCAPTCHA
    sitekey="6Le9Tc0ZAAAAAN6UjLt0vyXioE0InZsYy6r-yezk"
    onChange={(re) => setRegisterForm({...registerForm, reCaptcha: re})}
  />
          <p className="fieldset">
            <input type="submit" className="watch-this-button sendit" disabled={disabled} value={!disabled ?"Register": "Please wait ..."} onClick={handleSubmit}/>
          </p>

        </form>
      </Content>
      <div>
      <a href="https://wonulla.to/files/termsconditions.html" target="_blank" style={{ color: 'white', marginRight: '20px', textDecoration:'none'}}>
        Terms of Website Use
        </a>
        <a href="https://wonulla.to/files/privacy-policy.html" target="_blank" style={{ color: 'white', marginRight: '20px', textDecoration:'none'}}>
        Privacy Policy
        </a>
        <a href="https://wonulla.to/files/dmca.html" target="_blank" style={{ color: 'white', marginRight: '20px', textDecoration:'none'}}>
        DMCA
        </a>
        <a href="https://wonulla.to/files/refund.html" target="_blank" style={{ color: 'white', marginRight: '20px', textDecoration:'none'}}>
        Refund Policy
        </a>
      </div>
    </MainWrapper>
    </>
  );
};

export default Register;

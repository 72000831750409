import React, { useState, useEffect} from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

const Wrapper = styled.div`
 @media only screen and (max-width: 700px) {
  .notMobile {
    display:none;
  }
 }
`;

const NewsBox = styled.div`
width: calc(100% - 40px);
background-color:#298eea;
margin:20px;
padding: 3px;
display: flex;
borderRadius: '4px';
@media only screen and (max-width: 550px) {
  margin: 0;
  width: 100%;
}
`;

const NewsWrapper = () => {
  const news = useSelector(redux => redux.note);
  const [n, setN] = useState();
  useEffect(()=> {
    const cached = JSON.parse(localStorage.getItem('note'));
    setN(cached);
  }, [])
  if (!news) return null;
  if (n && n.updatedAt === news.updatedAt) return null;



  const closeNews = () => {
    setN(news)
    localStorage.setItem('note', JSON.stringify(news));
  };

  return (
    <Wrapper>
    <div style={{ width: '100%', height: '10px'}}/>
    {(window.location.pathname.split('/')[1] === 'movie'
    || window.location.pathname.split('/')[1] === 'serie')
    && (
      <div style={{ width: '100%', height: '30px'}}/>
    )}
    <NewsBox>
      <div style={{ backgroundColor: '#2177c4', padding: '20px', borderRadius: '2px'}}>
      <i className="fas fa-bullhorn" style={{fontSize: '18px', color: 'rgba(255,255,255,0.6'}} />
      </div>
      <div style={{
        width: '100%',
        padding: '20px',
        textAlign: 'left',
        color: 'white',
      }}>
      {news.note}
      </div>
      <button
      onClick={closeNews}
      style={{
        background: '#2177c4',
        padding: '20px',
        borderRadius: '2px',
        border: 'none',
        cursor: 'pointer',
      }}>
      <i className="fas fa-times" style={{fontSize: '18px', color: 'rgba(255,255,255,0.6'}}/>
      </button>
    </NewsBox>
    </Wrapper>
  );
}

export default NewsWrapper;
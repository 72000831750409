import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import MovieItem from '../components/MovieItem';
import getMovies from '../redux/actions/movies.actions';
import NewsWrapper from '../components/NewsWrapper';
import EpisodeItem from '../components/EpisodeItem';


const MainWrapper = styled.div`
position: relative;
color:white;
display: block;
vertical-align: top;
background: #1b1b1b;
height:100%;
min-height: calc(100vh - 70px);
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
margin: 70px 0 0 100px;
z-index: 1;
`;

const SectionWrapper = styled.section`
padding: 10px 20px;
`;

const Pagination = styled.nav`
margin: 60px 0;

& ul{
  text-align: center;
}

& ul li{
  font-size: 16px;
  display: inline-block;
  padding: 0 5px;
}

& ul li button{
  cursor:pointer;
  border: none;
  display: inline-block;
  color: rgba(255,255,255,.7);
  background: rgba(255, 255, 255, 0.12);
  height: 50px;
  line-height: 50px;
  padding: 0 23px;
  border-radius: 30px;
  transition: .3s ease;
  text-decoration: none;
}

& ul li button:hover {
  color: rgba(255,255,255,.85);
  background: rgba(255,255,255,.3);
}

& ul li button.current {
  color: #fff;
  background: #298eea;
}
`;
/*
const movies = [];
for (let i = 0; i < 60; i += 1) {
  movies.push({
    title: 'Green Book',
    rating: 8.2,
    release_date: '2018-1-1',
    id: 343,
    poster_path: 'https://image.tmdb.org/t/p/w342/h70wRv6iWxiqED4orqfxcEl74Rc.jpg',

  });
} */


const Search = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const search = searchParams.get('s');
  const [movies, setMovies] = useState((location && location.state && location.state.params) || null);
  const [loading, setLoading] = useState(true);

  const getMovies = () => {
    setLoading(true);
    axios.get('/api/search?search='+search)
    .then(res => {
      setMovies(res.data);
      setLoading(false)
    });
  }

  useEffect(()=> {
    if (!movies) {
      getMovies();
    }
  },[movies]);

  return (
    <MainWrapper>
      <SectionWrapper>
      <NewsWrapper/>
      {movies && movies.map((item) => {
        if (item.tv_id) {
          return <EpisodeItem item={item} key={item.title}/>
        } else {
          return <MovieItem item={item} key={item.title}/>
        }
      })}
      {(!movies || movies.length === 0) && !loading  &&(
        <>No results.</>
      )}
            {(!movies || movies.length === 0) && loading &&(
        <>Searching...</>
      )}
      </SectionWrapper>
    </MainWrapper>
  );
};

export default Search;

import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton,
} from "video-react";
import "video-react/dist/video-react.css";
import axios from "axios";

import { convert } from "../helper";
import { API_URL } from "../config";
import EpisodeItem from "../components/EpisodeItem";
import ClosedCaptionButton from "video-react/lib/components/control-bar/ClosedCaptionButton";
import { updateLastWatch } from "../redux/actions/user.actions";
import {
  startDownload,
  finishDownload,
} from "../redux/actions/downloads.actions";
import NewsWrapper from "../components/NewsWrapper";
import image from "../img/black_bg.jpg";
import SimilarEpisodes from "../components/SimilarEpisode";
import WatchButton from "./watchbutton";
import ShowPremiumWarning from "../components/ShowPremiumWarning";
import DownloadButton from "./DownloadButton";

const MainWrapper = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(${(props) => `https://image.tmdb.org/t/p/w780/${props.bgImage}`})
      no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  display: block;
  vertical-align: top;
  width: 100%;
  min-height: 100%;

  @media only screen and (max-width: 600px) {
    .video-react .video-react-icon-forward-10,
    .video-react .video-react-icon-replay-10 {
      display: none;
    }
    .video-react .video-react-control {
      width: 2em;
    }
  }
  @media only screen and (max-width: 450px) {
    .video-react-time-control {
      display: none;
    }
  }
`;

const SectionWrapper = styled.section`
  margin-top: 70px;
  margin: auto;
  padding-bottom: 30px;
  width: 80%;
  @media screen and (max-width: 770px) {
    width: 100%;
  }
  max-width: 1300px;
  position: relative;
  padding-top: 30px;
`;

const Video = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin: 40px auto 0 auto;
  padding-right: 0px;
  vertical-align: top;
  z-index: 1;
  background-color: #000000;

  &.fullScreen {
    position: fixed;
    z-index: 1000;
    top: 0;
    margin-top: 0;
    left: 0;
    bottom: 0;
  }

  .video-react-big-play-button {
    display: none;
  }

  .video-react {
    height: 100%;
  }

  .video-react .video-react-video {
    position: static !important;
  }

  & img {
    width: 100%;
    z-index: 100;
    display: block;
    opacity: 0.55;
  }

  & i.fa-play {
    position: absolute;
    font-size: 54px;
    color: #d0d2d4;
    z-index: 190;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
  }
  .fa-play:hover {
    color: white;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 100%;
  margin: auto;
  padding: 30px;
  color: #fff;
  background: rgb(19, 19, 19) !important;
  box-shadow: 0px 16px 13px -10px rgba(0, 0, 0, 0.8);
`;

const SerieInfo = styled.div`
  width: 75%;
  @media screen and (max-width: 770px) {
    width: 100%;
    margin-bottom: 20px;
  }
  display: inline-block;
  vertical-align: top;
  padding: 0;
  position: relative;
  z-index: 10;
  text-align: left;

  & h1 {
    font-size: 36px;
    line-height: 1;
    padding-bottom: 15px;
    font-weight: 300;
  }

  & p {
    color: rgba(255, 255, 255, 0.71);
    font-size: 16px;
    line-height: 1.5em;
    padding-top: 20px;
  }
`;

const SerieData = styled.div`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  padding-bottom: 20px;

  & .rating {
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
  }

  & .rating > i {
    padding: 0;
    margin: 0 2px 0 0;
  }

  & span:first-child:before {
    visibility: hidden;
    content: "";
    position: absolute;
    background: transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    bottom: 23px;
    left: 50%;
    margin-left: -7px;
    border-top: 6px solid #2f3444;
    margin-top: 6px;
    z-index: 15;
  }

  & span:before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 2px;
    margin: 0 10px;
    background: rgba(255, 255, 255, 0.3);
    vertical-align: middle;
  }

  & span i {
    padding-right: 8px;
  }
`;

const Select = styled.select`
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  background: #414141;
  height: 30px;
  padding: 0 30px 0 15px;
  border: none;
  outline: none;
  border-radius: 3px;
`;

const SerieActions = styled.div`
  position: relative;
  z-index: 10;
  display: inline-block;
  width: 25%;
  @media screen and (max-width: 770px) {
    width: 100%;
  }
  vertical-align: top;

  & ul {
    list-style: none;
  }

  & a {
    text-decoration: none;
    transition: 0.3s ease;
  }

  & ul:first-child {
    margin-bottom: 30px;
  }

  & .extra {
    margin: auto;
  }

  & ul > li {
    position: relative;
    font-size: 16px;
    display: block;
    color: rgba(255, 255, 255, 0.7);
    background: rgb(33, 33, 33);
    text-align: center;
  }

  & ul li i {
    vertical-align: text-top;
    padding-right: 10px;
  }

  & ul > li a {
    color: rgba(255, 255, 255, 0.7);
    display: block;
  }

  & ul > li a:hover {
    color: #fff;
  }

  & .extra li {
    text-align: left;
    display: block;
    position: relative;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
    background: rgb(33, 33, 33);
  }

  & .extra li div {
    padding: 16px 20px 16px 68px;
    cursor: pointer;
  }

  & .extra li div:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.1);
  }

  & .extra li .download {
    padding: 16px 20px 16px 68px;
  }

  & .extra li:first-child {
    border-radius: 5px 5px 0 0;
    background-color: #212121;
  }

  & .extra li:last-child {
    border-radius: 0 0 5px 5px;
    background-color: #212121;
  }

  & .extra li div i {
    position: absolute;
    top: 0;
    left: 0;
    padding: 16px;
    background: rgba(0, 0, 0, 0.1);
    height: 53px;
  }

  & li i {
    vertical-align: text-top;
  }

  & #subtitle-button label {
    display: block;
    padding: 16px 20px;
    cursor: pointer;
  }

  & ul li#subtitles {
    border-radius: 5px 5px 0 0;
  }

  & ul > li:nth-child(even) {
    background-color: #212121;
    border-top: 0px;
    border-bottom: 0px;
  }

  & .play {
    position: relative;
    font-weight: 700;
    color: #fff;
    background: #298eea;
    padding: 18px 25px 18px 25px;
    border-radius: 5px;
    margin-top: 5px;
    cursor: pointer;
  }

  & #quality-button {
    display: none !important;
  }
`;

const ItemDetailsInner = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  padding: 10px;
  left: 0px;
  right: 0px;

  & h2 {
    color: #fff;
    font-size: 19px;
    font-weight: 400;
  }

  & .rating {
    bottom: 5px;
    left: 10px;
    position: absolute;
    right: 10px;
  }

  & .rating > div {
    width: auto;
    font-size: 14px;
    padding: 1px 7px;
    border-radius: 2px;
  }

  & a {
    text-decoration: none;
  }

  & .container {
    float: left;
    border: 1px solid rgba(255, 255, 255, 0.31);
  }

  & .container > a {
    transition: 0.3s ease;
    color: #fff;
  }

  & a > i {
    float: left;
    padding-right: 5px;
    margin-top: 2px;
    font-size: 14px;
  }

  & a > span {
    border-radius: 100px;
    display: block;
    float: left;
    margin-top: 1px;
    line-height: 20px;
    padding-left: 0px;
    padding-right: 0px;
    transition: background-color 0.25s ease-in-out 0s;
    width: auto;
  }

  & .mfYear {
    float: right;
    line-height: 26px;
    text-align: right;
  }
`;

const TvDetails = styled.section`
  position: relative;
  margin-top: 30px;
  padding-top: 30px;
  z-index: 10;
  border-top: 1px solid rgb(48, 48, 48);
  text-align: left;

  & h2 {
    font-size: 24px;
    line-height: 1.5em;
  }
`;

const TvDetailsSeasons = styled.div`
  display: inline-block;
  font-size: 15px;
  vertical-align: top;
  width: 20%;
  @media screen and (max-width: 770px) {
    width: 100%;
  }
  padding-right: 20px;

  & ol {
    position: relative;
    max-height: 340px;
    padding-left: 10px;
    padding-right: 15px;
    margin-left: -10px;
    overflow-y: scroll;
    overflow-x: hidden;
    list-style: none;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.2);
      opacity: 0.5;
      border-radius: 4px;
      transition: background-color 0.2s linear;
      right: 0;
    }
    ::-webkit-scrollbar-thumb {
      opacity: 0.6;
    }
    ::-webkit-scrollbar-thumb:hover {
      opacity: 0.9;
    }
  }

  & ol li {
    position: relative;
    color: rgba(255, 255, 255, 0.7);
    background: #212121;
    padding: 13px 15px;
    cursor: pointer;
    border-bottom: 0px;
    line-height: 14px;
  }

  & ol li.active {
    color: #fff;
    background: #298eea !important;
    line-height: 14px;
  }

  & ol li.active:after {
    content: "";
    position: absolute;
    top: 0;
    right: -30px;
    border-color: transparent transparent transparent #298eea;
    border-style: solid;
    border-width: 20px 15px;
  }
`;

const TvDetailsEpisodes = styled.div`
  width: 40%;
  @media screen and (max-width: 770px) {
    width: 100%;
  }
  padding: 0 20px;
  display: inline-block;
  font-size: 15px;
  vertical-align: top;

  & ol {
    position: relative;
    display: none;
    max-height: 340px;
    padding-left: 10px;
    padding-right: 15px;
    margin-left: -10px;
    overflow-y: scroll;
    overflow-x: hidden;
    list-style: none;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.2);
      opacity: 0.5;
      border-radius: 4px;
      transition: background-color 0.2s linear;
      right: 0;
    }
    ::-webkit-scrollbar-thumb {
      opacity: 0.6;
    }
    ::-webkit-scrollbar-thumb:hover {
      opacity: 0.9;
    }
  }

  & ol.active {
    display: block;
  }

  & ol li {
    position: relative;
    color: rgba(255, 255, 255, 0.7);
    background: #212121;
    padding: 13px 15px 13px 55px;
    counter-increment: listcounter;
    cursor: pointer;
    border-bottom: 0px;
    line-height: 15px;
  }

  & ol li:hover {
    color: #fff;
  }

  & ol li:nth-child(odd) {
    background: #212121;
  }

  & ol li:before {
    content: counter(listcounter) " ";
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    background: rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    line-height: 40px;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  & ol li.active {
    color: #fff;
    background: #298eea !important;
    line-height: 14px;
  }

  & ol li.active:after {
    content: "";
    position: absolute;
    top: 0;
    right: -30px;
    border-color: transparent transparent transparent #298eea;
    border-style: solid;
    border-width: 20px 15px;
  }

  & .ps-container > .ps-scrollbar-x-rail {
    display: none;
    position: absolute;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    opacity: 0;
    -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
    -moz-transition: background-color 0.2s linear, opacity 0.2s linear;
    -o-transition: background-color 0.2s linear, opacity 0.2s linear;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    bottom: 3px;
    height: 8px;
  }

  & .ps-container > .ps-scrollbar-y-rail {
    display: block !important;
    width: 5px !important;
    background-color: rgba(255, 255, 255, 0.2) !important;
    opacity: 0.5 !important;
  }
`;

const TvDetailsEpisodesInfo = styled.div`
  width: 40%;
  padding-left: 20px;
  @media screen and (max-width: 770px) {
    width: 100%;
    padding-left: 0;
  }
  display: inline-block;
  font-size: 15px;
  vertical-align: top;

  & p {
    position: relative;
    display: inline-block;
    width: 75%;
    height: 9em;
    padding-left: 20px;
    vertical-align: top;
    overflow: hidden;
  }

  & img {
    display: inline-block;
    width: 25%;
  }
`;

const EpisodeInfo = styled.div`

& h2{
  font-size: 24px;
  line-height: 1.5em;
}

& p.release-info{
  height: initial!important;
  width: 100%!important;
  padding: initial!important;
  margin-bottom: 20px!important;
  color: #969696;
  font-size: 16px;
  line-height: 1.5em;
}
}
`;

const ActionsList = styled.ul`
  width: 100%;
  margin-top: 30px;
  display: inline-block;
  vertical-align: top;

  & li{
    position: relative;
    font-size: 16px;
    display: block;
    width 100%: ;
    color: rgba(255,255,255,.7);
    background: rgb(33, 33, 33);
    text-align: center;
  }

  & li i {
    vertical-align: text-top;
    padding-right: 10px;
  }

  & #subtitles {
    border-radius: 5px 5px 0 0;
  }

  & #subtitle-button {
    display: block;
  }

  & #subtitle-button label {
    display: block;
    padding: 16px 20px;
    cursor: pointer;
  }

  & #subtitle-button label:hover {
    color: #fff;
    background: rgba(255,255,255,.1);
    border-color: #fff;
  }

  & #subtitle-button #subfile {
    display: none;
  }

  & .play {
    position: relative;
    font-weight: 700;
    color: #fff;
    background: #298eea;
    padding: 18px 25px 18px 25px;
    border-radius:5px;
    margin-top: 5px;
    cursor: pointer;
  }
`;

const bringLinks = (serie, server) => {
  const {
    s1Video720,
    s1Video1080,
    s2Video1080,
    s2Video720,
    s3Video720,
    s3Video1080,
    s4Video1080,
    s4Video720,
  } = serie;
  const links = {};
  if (server === "s1") {
    if (s1Video1080) links["hd"] = true;
    if (s1Video720) links["sd"] = true;
  }
  if (server === "s2") {
    if (s2Video1080) links["hd"] = true;
    if (s2Video720) links["sd"] = true;
  }
  if (server === "s3") {
    if (s3Video1080) links["hd"] = true;
    if (s3Video720) links["sd"] = true;
  }
  if (server === "s4") {
    if (s4Video1080) links["hd"] = true;
    if (s4Video720) links["sd"] = true;
  }
  return links;
};

const QualityChanger = ({ setActiveQuality, serie, server, activeQuality }) => {
  const [showQualities, setShowQualities] = useState();
  const [links, setLinks] = useState({});
  useEffect(() => {
    setLinks(bringLinks(serie, server));
  }, [serie, server]);

  if (!links.hd || !links.sd) return null;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      {showQualities && (
        <div
          style={{
            position: "absolute",
            borderRadius: "4px",
            padding: "10px",
            maxWidth: "80px",
            top: "0",
            transform: "translate(-50%,-110%)",
            backgroundColor: "rgb(39,39,39)",
          }}
        >
          <span
            style={
              activeQuality !== 1080 && activeQuality
                ? { cursor: "pointer", color: "rgba(255,255,255,0.5" }
                : { cursor: "pointer" }
            }
            onClick={() => {
              setActiveQuality(1080);
            }}
          >
            1080p
          </span>
          <br />
          <br />
          <span
            style={
              activeQuality !== 720
                ? { cursor: "pointer", color: "rgba(255,255,255,0.5" }
                : { cursor: "pointer" }
            }
            onClick={() => {
              setActiveQuality(720);
            }}
          >
            720p
          </span>
        </div>
      )}
      <button onClick={() => setShowQualities(!showQualities)}>
        <i className="fas fa-cog" style={{ fontSize: "16px" }} />
      </button>
    </div>
  );
};

const addToFavorites = (data) => {
  const tmp = localStorage.getItem("favorites")
    ? JSON.parse(localStorage.getItem("favorites"))
    : {};
  tmp[data.id] = {
    url:
      "/serie/" +
      data.id +
      "-" +
      encodeURI(data.title.split(" ").join("-").split("/").join("-")),
    img: data.poster,
  };
  localStorage.setItem("favorites", JSON.stringify(tmp));
  Swal.fire("Success", "Serie has been added to your favorites", "success");
};

const addToWatchList = (data) => {
  const tmp = localStorage.getItem("watchlist")
    ? JSON.parse(localStorage.getItem("watchlist"))
    : {};
  tmp[data.id] = {
    url:
      "/serie/" +
      data.id +
      "-" +
      encodeURI(data.title.split(" ").join("-").split("/").join("-")),
    img: data.poster,
  };
  localStorage.setItem("watchlist", JSON.stringify(tmp));
  Swal.fire("Success", "Serie has been added to your watchlist", "success");
};

const getSerie = (id) =>
  axios.get(`${API_URL}series/?tv_id=${id}&_limit=0`).then((res) => res.data);

const Serie = (props) => {
  const { cdnUrl } = useSelector((redux) => redux.statistics);
  let tmpid = useParams().id;
  const history = useHistory();
  const [id, setId] = useState(tmpid.split("-")[0].split("_")[0]);
  const [season, setSeason] = useState(tmpid.split("-")[0].split("_")[1] || 1);
  const [episode, setEpisode] = useState(
    tmpid.split("-")[0].split("_")[2] || 1
  );
  const [play, setPlay] = useState(tmpid.split("-")[0].split("_")[3] || false);
  const [series, setSeries] = useState({ empty: true });
  const [serie, setSerie] = useState({ empty: true });
  const [link, setLink] = useState();
  const [subtitles, setSubtitles] = useState();
  const ref = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const user = useSelector((redux) => redux.user);
  const dispatch = useDispatch();
  const [downloading, setDownloading] = useState();
  const [activeQuality, setActiveQuality] = useState();
  const [server, setServer] = useState("s1");
  const [activeSeason, setActiveSeason] = useState(season);
  const [fullScreen, setFullScreen] = useState();

  const checkTime = () => {
    if (
      ref2 &&
      ref2.current &&
      ref2.current.getState() &&
      ref2.current.getState().player &&
      !ref2.current.getState().player.paused
    ) {
      dispatch(updateLastWatch());
    }
  };

  const pausePlayer = () => {
    ref2.current.pause();
  };

  useEffect(() => {
    // stop checking free watch time
    // setInterval(() => checkTime(), 2 * 60 * 1000);
  }, []);

  const getCurrentEpisode = (episodes) => {
    let epi = episodes[0];
    episodes.forEach((item) => {
      if (
        item.seasonNumber === Number(season) &&
        item.episodeNumber === Number(episode)
      ) {
        epi = item;
      }
    });
    return epi;
  };

  const getSeasons = () => {
    if (!series || series.empty) return null;
    return [...new Set([...series].map((x) => x.seasonNumber))];
  };

  const nextEpisodeExist = (array, seas, epi) => {
    return array.find(
      (item) => item.episodeNumber === epi + 1 && item.seasonNumber === seas
    );
  };

  const getNextEpisode = (array, seas, epi) => {
    const nextEp = nextEpisodeExist(array, seas, epi);
    if (!nextEp) return false;
    window.location.href =
      "/serie/" +
      nextEp.tv_id +
      "_" +
      seas +
      "_" +
      (epi + 1) +
      "-" +
      encodeURI(nextEp.title.split(" ").join("-").split("/").join("-")) +
      "?autoplay=1";
  };

  useEffect(() => {
    if (ref2 && ref2.current) {
      if (!ref2.current.getState().player.isFullscreen && fullScreen) {
        setFullScreen(false);
      }
    }
    if (
      ref2 &&
      ref2.current &&
      ref2.current.getState() &&
      ref2.current.getState().player &&
      document.getElementsByClassName("video-react-video")
    ) {
      document.getElementsByClassName("video-react-video")[0].addEventListener(
        "ended",
        () => {
          getNextEpisode(series, serie.seasonNumber, serie.episodeNumber);
        },
        false
      );
    }
  });

  useEffect(() => {
    if (activeQuality) handleClickToWatch();
  }, [activeQuality, server]);

  const retrieveSerie = async () => {
    const tmp = await getSerie(id);
    if (tmp.length > 0) {
      setSeries(tmp);
      const epi = getCurrentEpisode(tmp);
      setSerie(epi);
    } else {
      //history.push('/series');
    }
  };

  useEffect(() => {
    retrieveSerie();
  }, [id]);

  const [autoPlay, setAutoPlay] = useState();

  /*useEffect(() => {
    if (serie && play && user && user.username && !autoPlay) {
      handleClickToWatch();
      setAutoPlay(true);
    }
  }, [serie, user]);*/

  const getDownload = async (quality) => {
    const { s1Bucket, s1Account, s1Video720, s1Video1080 } = serie;
    console.log(quality);
    if (quality === 720 && !s1Video720) return;
    if (quality === 1080 && !s1Video1080) return;
    let activeLink;
    if (quality === 720) {
      activeLink = await axios.get(
        `${API_URL}storage/download/${s1Video720}?server=s1`,
        {
          query: {
            s1Bucket,
            s1Account,
            server: "s1",
          },
        }
      );
    }
    if (quality === 1080) {
      activeLink = await axios.get(
        `${API_URL}storage/download/${s1Video1080}?server=s1`,
        {
          query: {
            s1Bucket,
            s1Account,
            server: "s1",
          },
        }
      );
    }
    console.log(activeLink);
    if (activeLink && activeLink.data) {
      let url = activeLink.data;
      if (url.includes("filebase"))
        url = url.replace("s3.filebase.com", "filebase.wonulla.to");
      Swal.fire(
        "Downloading",
        "Your download has been initiated.Please don't close the current tab. We will notify you as soon as it is completed.",
        "success"
      );
      dispatch(startDownload());
      axios({
        url,
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "video.mp4");
          document.body.appendChild(link);
          link.click();
          dispatch(finishDownload());
        })
        .catch((error) => {
          Swal.fire("Error", "Please try again later", "error");
          dispatch(finishDownload());
        });
      //window.open(activeLink.data);
    }
  };

  const getWatchingVideo = async (serie) => {
    if (!isPremium()) return;
    const {
      s1Bucket,
      s2Bucket,
      s1Domain,
      s2Domain,
      s1Video720,
      s1Video1080,
      s2Video1080,
      s2Video720,
      s3Bucket,
      s4Bucket,
      s3Domain,
      s4Domain,
      s3Video720,
      s3Video1080,
      s4Video1080,
      s4Video720,
    } = serie;
    const video = getVideoToWatch();
    let activeLink;
    if (video === "s1Video720") {
      activeLink = `${serie.s1Domain}/${serie.s1Bucket}/${s1Video720}`;
      setServer("s1");
    }
    if (video === "s2Video720") {
      activeLink = `${serie.s2Domain}/${serie.s2Bucket}/${s2Video720}`;
      setServer("s2");
    }
    if (video === "s3Video720") {
      activeLink = `${serie.s3Domain}/${serie.s3Bucket}/${s3Video720}`;
      setServer("s3");
    }
    if (video === "s4Video720") {
      activeLink = `${serie.s4Domain}/${serie.s4Bucket}/${s4Video720}`;
      setServer("s4");
    }
    if (video === "s1Video1080") {
      activeLink = `${serie.s1Domain}/${serie.s1Bucket}/${s1Video1080}`;
      setServer("s1");
    }

    if (video === "s2Video1080") {
      activeLink = `${serie.s2Domain}/${serie.s2Bucket}/${s2Video1080}`;
      setServer("s2");
    }

    if (video === "s3Video1080") {
      activeLink = `${serie.s2Domain}/${serie.s2Bucket}/${s3Video1080}`;
      setServer("s3");
    }
    if (video === "s4Video1080") {
      activeLink = `${serie.s4Domain}/${serie.s4Bucket}/${s4Video1080}`;
      setServer("s4");
    }
    if (activeLink) {
      if (cdnUrl)
        activeLink = activeLink.replace("s3.wasabisys.com/s1account", cdnUrl);
      setLink(activeLink);

      dispatch(updateLastWatch());
      var iOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      function detectMob() {
        const toMatch = [
          /Android/i,
          /webOS/i,
          /iPhone/i,
          /iPad/i,
          /iPod/i,
          /BlackBerry/i,
          /Windows Phone/i,
        ];

        return toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem);
        });
      }
      if (iOS || detectMob()) {
        ref2.current.toggleFullscreen();
        setFullScreen(true);
      }
    }
  };

  const isPremium = () => {
    if (user && user.token && !user.premiumUntil) return false;
    const until = new Date(user.premiumUntil).getTime();
    const today = Date.now();
    if (until - today >= 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleClickToWatch = () => {
    if (serie && !serie.empty) {
      getWatchingVideo(serie);
    }
  };

  const VideoPlayer = styled(Player)`
    padding-top: 0 !important;
    font-family: Roboto;
    font-size: 13px;
    position: absolute;
    top: 0;
  `;

  useEffect(() => {
    if (link) {
      getWatchingVideo(serie);
    }
  }, [server]);

  const getAvailableLinks = () => {
    const {
      s1Video720,
      s1Video1080,
      s2Video1080,
      s2Video720,
      s3Video720,
      s3Video1080,
      s4Video1080,
      s4Video720,
    } = serie;
    const tmp = {};

    if (s1Video1080) tmp.s1 = { s1Video1080: true };
    if (s1Video720) tmp.s1 = { s1Video720: true };
    if (s2Video1080) tmp.s2 = { s2Video1080: true };
    if (s2Video720) tmp.s2 = { s2Video720: true };
    if (s3Video1080) tmp.s3 = { s3Video1080: true };
    if (s3Video720) tmp.s3 = { s3Video720: true };
    if (s4Video1080) tmp.s4 = { s4Video1080: true };
    if (s4Video720) tmp.s4 = { s4Video720: true };
    return tmp;
  };

  const handleUploadSubtitles = (event) => {
    var selectedFile = event.target.files[0];
    if (!selectedFile) return;
    const reader = new FileReader();
    const reader2 = new FileReader();
    let t = "";
    reader.onload = (e) => {
      const webvtt = convert(e.target.result);
      const formed = webvtt;
      t = new Blob([webvtt], {
        type: "text/plain",
      });
      reader2.readAsDataURL(t);
    };

    reader2.onload = (e) => {
      setSubtitles(e.target.result);
    };

    reader.readAsText(event.target.files[0]);
  };

  const getVideoToWatch = () => {
    const {
      s1Video720,
      s1Video1080,
      s2Video1080,
      s2Video720,
      s3Video720,
      s3Video1080,
      s4Video1080,
      s4Video720,
    } = serie;
    if (server === "s1") {
      if (s1Video1080 && (!activeQuality || activeQuality === 1080))
        return "s1Video1080";
      if (s1Video720) return "s1Video720";
      if (s2Video1080 && (!activeQuality || activeQuality === 1080))
        return "s2Video1080";
      if (s2Video720) return "s2Video720";
      if (s3Video1080 && (!activeQuality || activeQuality === 1080))
        return "s3Video1080";
      if (s3Video720) return "s3Video720";
      if (s3Video1080 && (!activeQuality || activeQuality === 1080))
        return "s3Video1080";
      if (s3Video720) return "s3Video720";
      if (s4Video1080 && (!activeQuality || activeQuality === 1080))
        return "s4Video1080";
      if (s4Video720) return "s4Video720";
    }
    if (server === "s2") {
      if (s2Video1080 && (!activeQuality || activeQuality === 1080))
        return "s2Video1080";
      if (s2Video720) return "s2Video720";
      if (s1Video1080 && (!activeQuality || activeQuality === 1080))
        return "s1Video1080";
      if (s1Video720) return "s1Video720";
      if (s3Video720) return "s3Video720";
      if (s3Video1080 && (!activeQuality || activeQuality === 1080))
        return "s3Video1080";
      if (s3Video720) return "s3Video720";
      if (s4Video1080 && (!activeQuality || activeQuality === 1080))
        return "s4Video1080";
      if (s4Video720) return "s4Video720";
    }
    if (server === "s3") {
      if (s3Video1080 && (!activeQuality || activeQuality === 1080))
        return "s3Video1080";
      if (s3Video720) return "s3Video720";
      if (s1Video1080 && (!activeQuality || activeQuality === 1080))
        return "s1Video1080";
      if (s1Video720) return "s1Video720";
      if (s2Video1080 && (!activeQuality || activeQuality === 1080))
        return "s2Video1080";
      if (s2Video720) return "s2Video720";
      if (s4Video1080 && (!activeQuality || activeQuality === 1080))
        return "s4Video1080";
      if (s4Video720) return "s4Video720";
    }
    if (server === "s4") {
      if (s4Video1080 && (!activeQuality || activeQuality === 1080))
        return "s4Video1080";
      if (s4Video720) return "s4Video720";
      if (s1Video1080 && (!activeQuality || activeQuality === 1080))
        return "s1Video1080";
      if (s1Video720) return "s1Video720";
      if (s2Video1080 && (!activeQuality || activeQuality === 1080))
        return "s2Video1080";
      if (s2Video720) return "s2Video720";
      if (s3Video1080 && (!activeQuality || activeQuality === 1080))
        return "s3Video1080";
      if (s3Video720) return "s3Video720";
      if (s3Video1080 && (!activeQuality || activeQuality === 1080))
        return "s3Video1080";
      if (s3Video720) return "s3Video720";
    }
  };
  const seasons = getSeasons();
  if (!serie || serie.empty) return null;
  return (
    <MainWrapper bgImage={serie.backdrop}>
      <Helmet>
        <title>Wonulla | {serie.title}</title>
        <link rel="canonical" href="https://wonulla.to/" />
        <meta name="description" content={serie.overview} />
      </Helmet>
      <ShowPremiumWarning pause={pausePlayer} player={ref2} />
      <SectionWrapper>
        <NewsWrapper />
        <Video className={fullScreen ? "fullScreen" : ""}>
          <img src={image} />
          <VideoPlayer
            ref={ref2}
            autoPlay
            style={{ paddingTop: 0, width: "100%" }}
            playsInline={false}
            id="myVideoPlayer"
          >
            <source src={isPremium() ? link : null}></source>
            <ControlBar>
              <ReplayControl className="notMobile" seconds={10} order={1.0} />
              <ForwardControl className="notMobile" seconds={10} order={1.2} />
              <QualityChanger
                order={7.2}
                setActiveQuality={setActiveQuality}
                serie={serie}
                server={server}
                activeQuality={activeQuality}
              />
              <CurrentTimeDisplay order={4.1} />
              <TimeDivider order={4.2} />
              {subtitles && <ClosedCaptionButton order={7.1} />}
              {nextEpisodeExist(
                series,
                serie.seasonNumber,
                serie.episodeNumber
              ) && (
                <div
                  order={6.1}
                  style={{
                    width: "40px",
                    fontSize: "18px",
                    paddingTop: "10px",
                    cursor: "pointer",
                  }}
                >
                  <i
                    className="fas fa-step-forward"
                    onClick={() =>
                      getNextEpisode(
                        series,
                        serie.seasonNumber,
                        serie.episodeNumber
                      )
                    }
                  />
                </div>
              )}

              <VolumeMenuButton order={6.2} vertical />
            </ControlBar>
            {subtitles && (
              <track
                label="English"
                kind="subtitles"
                srcLang="en"
                src={subtitles}
                default
              ></track>
            )}
          </VideoPlayer>
          {!link && (
            <div
              onClick={() => document.getElementById("watchButton").click()}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                cursor: "pointer",
                width: "100%",
              }}
            >
              <img src={image} />
              <img
                src={`https://image.tmdb.org/t/p/w780/${serie.backdrop}`}
                style={{ position: "absolute", top: 0 }}
              />
              <i className="fas fa-play" />
            </div>
          )}
        </Video>
        <InnerContainer>
          <SerieInfo>
            <h1>{serie.title}</h1>
            <SerieData>
              <span className="rating">
                <i className="fas fa-star" /> {serie.vote_average.toFixed(1)}
              </span>
              <span className="serie-date">
                <i className="far fa-calendar" />{" "}
                {serie.release_date && serie.release_date.substring(0, 4)}
              </span>
              <span className="serie-time">
                <i className="far fa-clock" /> {serie.runtime} min
              </span>
              <span className="serie-genre">
                <i className="fas fa-mask" />{" "}
                {serie &&
                  serie.genres &&
                  serie.genres.map((genre) => genre.name).join(", ")}
              </span>
            </SerieData>
            <p
              style={{
                textAlign: "justify",
                maxHeight: "120px",
                overflow: "hidden",
                paddingRight: "20px",
              }}
            >
              {serie.overview}
            </p>
            {getAvailableLinks() &&
              Object.keys(getAvailableLinks()) &&
              Object.keys(getAvailableLinks()).length > 1 && (
                <p>
                  <b>Select server: </b>
                  <Select
                    className="select"
                    onChange={(e) => setServer(e.target.value)}
                  >
                    {Object.keys(getAvailableLinks()).map((key) => {
                      if (key === "s1") {
                        return (
                          <option value={key} selected="selected">
                            Server {key.charAt(1)}
                          </option>
                        );
                      }
                      return (
                        <option value={key}>Server {key.charAt(1)}</option>
                      );
                    })}
                  </Select>
                </p>
              )}
            <p style={{ display: "none" }}>
              <b>
                Try another video player (these links open in almost-fullscreen
                by default):
              </b>
            </p>
            <ul
              style={{
                margin: "auto",
                paddingTop: "5px",
                listStyle: "none",
                display: "none",
              }}
            >
              <li
                style={{
                  margin: "0 0 10px 0",
                  fontSize: "14px",
                }}
              >
                <a
                  style={{
                    color: "rgba(255,255,255,.7)",
                    pointerEvents: "none",
                  }}
                >
                  <span>
                    1. New video player (supports Chromecast on Chrome for
                    Mac/Windows, AirPlay on Safari)
                  </span>
                </a>{" "}
                | <span>720p</span> - <span>1080p</span>
              </li>
              <li
                style={{
                  margin: "0 0 10px 0",
                  fontSize: "14px",
                }}
              >
                <a
                  style={{
                    color: "rgba(255,255,255,.7)",
                    pointerEvents: "none",
                  }}
                >
                  <span>
                    2. Native video player (supports AirPlay and PIP on Safari)
                  </span>
                </a>{" "}
                | <span>720p</span> - <span>1080p</span>
              </li>
            </ul>
          </SerieInfo>
          <SerieActions>
            <ul className="extra">
              <li
                onClick={() =>
                  addToFavorites({
                    poster: serie.poster,
                    id,
                    title: serie.title,
                  })
                }
              >
                <div>
                  <i className="far fa-heart" />
                  <span>Add to favorites</span>
                </div>
              </li>
              <li
                onClick={() =>
                  addToWatchList({
                    poster: serie.poster,
                    id,
                    title: serie.title,
                  })
                }
              >
                <div>
                  <i className="fas fa-history" />
                  <span>Watch later</span>
                </div>
              </li>
              <DownloadButton
                id="downloadButton"
                ref2={ref3}
                getDownload={getDownload}
                serie={serie}
              />
              {/*(serie.s1Video720 && false) && (
              <li onClick={() => getDownload(720)}>
              <div className="download">                  
                  <i className="fas fa-download" />
                  <span>Download 720p</span>
              </div>
            </li>
              )}
              {(serie.s1Video1080 && false) && (
              <li onClick={() => getDownload(1080)}>
                <div className="download">
                    <i className="fas fa-download" />
                    <span>Download 1080p</span>
                </div>
              </li>
                            )*/}
            </ul>
          </SerieActions>
          <TVChooseEpisode
            getDownload={getDownload}
            ref3={ref3}
            ref2={ref2}
            serie={serie}
            series={series}
            season={season}
            seasons={seasons}
            getWatchingVideo={getWatchingVideo}
            pausePlayer={pausePlayer}
            handleUploadSubtitles={handleUploadSubtitles}
            episode={episode}
          />
          {serie && !serie.empty && <SimilarEpisodes movie={serie} />}
        </InnerContainer>
      </SectionWrapper>
      {downloading && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            backgroundColor: "rgba(0,0,0,0.2)",
            zIndex: 10000,
          }}
        >
          <SpinnerWrapper
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              backgroundColor: "#272727",
              width: "200px",
              height: "200px",
              borderRadius: "10px",
              boxShadow: "rgba(255,255,255,0.2) 0 2px 14px 0",
            }}
          >
            <i
              className="fas fa-spinner"
              style={{
                color: "rgb(41,142,234)",
                fontSize: "80px",
                marginTop: "60px",
              }}
            />
          </SpinnerWrapper>
        </div>
      )}
    </MainWrapper>
  );
};

export default Serie;

const SpinnerWrapper = styled.div`
  & i {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const TVChooseEpisode = ({
  ref2,
  serie,
  seasons,
  series,
  season,
  getWatchingVideo,
  pausePlayer,
  handleUploadSubtitles,
  episode,
  getDownload,
  ref3,
}) => {
  const [activeSeason, setActiveSeason] = useState(season);
  const ref = useRef();
  return (
    <TvDetails>
      <TvDetailsSeasons>
        <h2>Seasons</h2>
        <ol>
          {seasons &&
            seasons.map((item) => {
              if (Number(activeSeason) !== item) {
                return (
                  <li onClick={() => setActiveSeason(item)}>Season {item}</li>
                );
              } else {
                return (
                  <li onClick={() => setActiveSeason(item)} className="active">
                    Season {item}
                  </li>
                );
              }
            })}
        </ol>
      </TvDetailsSeasons>
      <TvDetailsEpisodes>
        <h2>Episodes</h2>
        <ol className="active ps-container ps-active-y">
          {series &&
            !series.empty &&
            series.map((item) => {
              if (item.seasonNumber !== Number(activeSeason)) return null;
              if (
                item.seasonNumber === Number(season) &&
                item.episodeNumber === Number(episode)
              ) {
                return <li className="active">{item.episodeTitle}</li>;
              } else {
                return (
                  <li
                    onClick={() =>
                      (window.location.href = `/serie/${
                        item.tv_id
                      }_${activeSeason}_${item.episodeNumber}_p-${encodeURI(
                        item.title.split(" ").join("-").split("/").join("-")
                      )}`)
                    }
                  >
                    {item.episodeTitle}
                  </li>
                );
              }
            })}
        </ol>
      </TvDetailsEpisodes>
      <TvDetailsEpisodesInfo>
        <EpisodeInfo>
          <h2>{serie.episodeTitle}</h2>
          <p
            className="release-info"
            style={{
              overflowWrap: "break-word",
            }}
          >
            {serie.seasonNumber && <>Season {serie.seasonNumber}</>},
            {serie.episodeNumber && <> Episode {serie.episodeNumber}</>}
            <br />
            {serie.episodeAirDate && <> Air date: {serie.episodeAirDate}</>}
          </p>
          {serie.episodeBackdrop && (
            <img
              src={"https://image.tmdb.org/t/p/w300/" + serie.episodeBackdrop}
            />
          )}

          <p
            style={{
              overflowWrap: "break-word",
              textAlign: "justify",
              height: "160px",
            }}
          >
            {serie.episodeOverview}
          </p>
        </EpisodeInfo>
        <ActionsList>
          <li id="subtitles" onClick={() => ref.current.click()}>
            <form
              className="settings-button"
              method="POST"
              action="/series/uploadSub"
              encType="multipart/form-data"
              id="subtitle-button"
            >
              <label htmlFor="subfile">
                <input
                  ref={ref}
                  type="file"
                  hidden
                  onChange={handleUploadSubtitles}
                />
                <i className="fas fa-upload" />
                Upload subtitle .srt
              </label>
            </form>
          </li>
          <WatchButton
            id="watchButton"
            ref2={ref2}
            getWatchingVideo={getWatchingVideo}
            series={true}
            movie={serie}
            pause={() => pausePlayer()}
          />
        </ActionsList>
      </TvDetailsEpisodesInfo>
    </TvDetails>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const MainWrapper = styled.div`
position: relative;
color:white;
display: block;
vertical-align: top;
background: #1b1b1b;
width: calc(100% - 100px);
min-height: 100%;
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
margin: 70px 0 0 100px;
height:100vh;
z-index: 1;
`;

const InnerContainer = styled.section`
  padding: 0 30px;
  margin: auto;
  padding-bottom: 30px;
  margin-bottom: 45px;
  width: 80%;
  max-width: 1300px;
  position: relative;
  padding-top: 30px;
  text-align: center;

  & h1 {
    font-size: 35px;
    margin-bottom: 25px;
    font-weight: 300;
    line-height: 1.5em;
  }

  & .form{
    width: 600px;
    margin: 20px auto 0 auto;
    text-align: left;
    padding: 20px;
  }

  & ol {
    list-style: none;
    padding-left: 0;
  }

  & h3{
    font-size: 17px;
    margin-top: 22px;
    margin-bottom: 10px;
    font-weight: 300;
    line-height: 1.5em;
  }

  & #contactform span {
    opacity: 0.65;
  }

  & fieldset p {
    position: relative;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.5em;
  }

  & #contactform input[type=text] {
    color: #fff;
    background: rgba(163, 163, 163, 0.07);
    padding: 15px 20px 15px 50px;
    border: none;
    outline: none;
    width: 100%;
  }

  & #contactform input[type=submit] {
    padding: 16px 25px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    background: #298eea;
    color: #FFF;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }

  & .form input {
    margin: 0;
    border-radius: 3px;
    font-size: 16px;
  }

  & input::placeholder {
    color: #fff;
    opacity: 1;
  }

  & #contactform label {
    position: absolute;
    top: 10px;
    left: 20px;
  }

  & .form label {
    color: #666;
    font-size: 14px;
  }
`;

const AccountTabs = styled.ul`
  border-bottom: 1px solid rgba(255,255,255,.1); 
  list-style: none;

  & li {
    display: inline-block;
    font-size: 15px;
    margin-bottom: -2px;
  }

  & li a {
    display: inline-block;
    color: rgba(255,255,255,.7);
    font-weight: 600;
    padding: 0 20px;
    border-bottom: 1px solid transparent;
    text-decoration: none;
    transition: .3s ease;
  }

  & li a:hover span {
    border-color: rgba(255,255,255,0.2);
  }

  & li a span {
      display: inline-block;
      padding: 15px 0px;
      border-bottom: 1px solid transparent;
  }

  & li.active a span {
    color: #fff;
    border-color: #fff;
  }
`;



const RequestAdd = (props) => (
  <MainWrapper>
    <InnerContainer>
      <h1>Request Movie / TV List</h1>
      <AccountTabs>
        <li><Link to="/request"><span>View all request</span></Link></li>
        <li><Link to="/request?filled=1"><span>View filled request</span></Link></li>
        <li className="active"><Link to="/request/add"><span>Make a new Request</span></Link></li>
      </AccountTabs>
      <form method="POST" id="contactform" className="form">
        <ol>
          <h3>Please add an IMDB-link in this format <span>http://www.imdb.com/title/tt0111161/</span></h3>
          <fieldset>
            <p>
              <input id="imdb_link" type="text" name="imdb_link" value="" placeholder="Imdb link"/>
              <label><i className="fas fa-align-justify"></i></label>
            </p>
            <input type="submit" name="submit" value="Request" id="ss-submit" className="jfk-button jfk-button-action"/>
          </fieldset>
        </ol>
      </form>
    </InnerContainer>
  </MainWrapper>

);

export default RequestAdd;
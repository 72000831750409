import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const ShowPremiumWarning = ({ pause, player }) => {
  const user = useSelector((redux) => redux.user);
  const history = useHistory();
  const ref2 = player;

  const isPremium = () => {
    if (user && user.token && !user.premiumUntil) return false;
    if (user && !user.token) return undefined;
    const until = new Date(user.premiumUntil).getTime();
    const today = Date.now();
    if (until - today >= 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isPremium() === false) {
      Swal.fire({
        title: "Error",
        text: "You need to be premium member in order to watch.",
        type: "error",
        confirmButtonText: "Become Premium",
      }).then(
        () => history.push("/premium"),
        () => false
      );
      pause();
      return;
    }
  });

  return null;
};

export default ShowPremiumWarning;

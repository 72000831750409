import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { pause } from 'video-react/lib/actions/player';



const WatchButton = (props) => {
  const user = useSelector(redux => redux.user);
  const history = useHistory();
  const ref2 = props.ref2;

  const isPremium = () => {
    if (!user) return false;
    if (user && !user.premiumUntil) return false;
    const until = new Date(user.premiumUntil).getTime();
    const today = Date.now();
    if (until - today >= 0) {
      return true;
    }
  }

  const handleClickToWatch =() => {
    if (!user || !user.username) {
      Swal.fire({
        title:'Error',
        text: 'You need to login in order to watch a movie',
        type: 'error',
        confirmButtonText: 'Login'        
      }).then(()=> history.push('/login'), ()=> false);
      return;
    } else {
      if (!user.freeTimeUntil) {

      } else {
        const hasWatch = new Date(user.freeTimeUntil);
        if (!isPremium() && hasWatch.getTime() > Date.now() && user.timeLeft <= 0) {
          props.ref2.current.player.pause();
          Swal.fire({
            title:'Error',
            text: 'You need to be premium member in order to watch more movies this month',
            type: 'error',
            confirmButtonText: 'Become Premium'        
          }).then(()=> history.push('/premium'), ()=> false);
          return;
        }
      }
    }
    if (props.movie && !props.movie.empty) {
      console.log(444444);
      props.getWatchingVideo(props.movie);
    }
  }
  const [count,setCount] = useState(0);
  useEffect(() => {
    if (user && user.length === 0)return;
    if (count === 0) {
      setCount(1);
      return;
    }
    if (!user) {
      Swal.fire({
        title:'Error',
        text: 'You need to login in order to watch a movie',
        type: 'error',
        confirmButtonText: 'Login'        
      }).then(()=> history.push('/login'), ()=> false);
      return;
    } else {
      if (!user.freeTimeUntil) {

      } else {
        const hasWatch = new Date(user.freeTimeUntil);
        if (!isPremium() && hasWatch.getTime() > Date.now() && user.timeLeft <= 0) {
          props.pause();
          Swal.fire({
            title:'Error',
            text: 'You need to be premium member in order to watch more movies this month',
            type: 'error',
            confirmButtonText: 'Become Premium'        
          }).then(()=> history.push('/premium'), ()=> false);
          return;
        }
      }
    }
  });

  const [clickDone, setClickDone] = useState(false);

  useEffect(() => {
    console.log(4,window.location.search === '?autoplay=1')
    const hasWatch = new Date(user.freeTimeUntil);
    if (props.movie && user && user.username && props.series && (isPremium() || hasWatch.getTime() > Date.now() && user.timeLeft > 0 ) &&
    ref2 && ref2.current && ref2.current.getState() && ref2.current.getState().player
    && ref2.current.getState().player.paused && !ref2.current.getState().player.ended && !clickDone
    && window.location.search === '?autoplay=1') {
      handleClickToWatch();
      setClickDone(true);
    }
  }, [props.movie, user]);

  return (
    <li id="watchButton" onClick={() => handleClickToWatch()}>
    <div className="play">
      <i
        className="fas fa-play"
        style={{
          fontSize: '10px',
          verticalAlign: 'middle',
          marginTop: '-4px',
        }}
      />
      Watch now
    </div>
  </li>
  )
};


export default React.memo(WatchButton);

import { GET_DOWNLOADS } from './action.types';

export const startDownload = () => (dispatch,getState) => {
  const downloads = getState().downloads;
  dispatch({
    type:GET_DOWNLOADS,
    payload: downloads + 1,
  })
};

export const finishDownload = () => (dispatch,getState) => {
  const downloads = getState().downloads;
  dispatch({
    type:GET_DOWNLOADS,
    payload: downloads - 1,
  })
};
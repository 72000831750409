import React from "react";
import styled from "@emotion/styled";
import Axios from "axios";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

const MainWrapper = styled.div`
  position: relative;
  color: white;
  display: block;
  vertical-align: top;
  background: #1b1b1b;
  width: calc(100% - 100px);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin: 70px 0 0 100px;
  height: 100%;
  min-height: calc(100vh - 70px);
  z-index: 1;
`;

const InnerContainer = styled.section`
  margin-top: 200px;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  padding-bottom: 30px;
  margin-bottom: 45px;
  width: 80%;
  max-width: 1300px;

  & h1 {
    font-size: 40px;
    font-weight: 300;
    line-height: 1.5em;
  }

  & .form {
    padding: 20px;
    margin: auto;
  }

  & .form .fieldset:first-child {
    margin-top: 0;
  }

  & .form .fieldset {
    position: relative;
    margin: 20px 0;
  }

  & p {
    font-size: 16px;
    line-height: 1.5em;
  }

  & .form label.image-replace {
    position: absolute;
    top: 50%;
    left: 20px;
    margin-top: -7px;
  }

  & .form label {
    color: #666;
  }

  & .form label.image-replace i {
    font-size: 14px;
    vertical-align: top;
  }

  & .form input.has-padding {
    padding: 12px 20px 12px 50px;
  }

  & .form input.full-width {
    width: 100%;
  }

  & .form input {
    margin: 0;
    padding: 0;
    border-radius: 3px;
  }

  & input {
    font-size: 16px;
  }

  & .form .fieldset:last-child {
    margin-bottom: 0;
  }

  & form input[type="submit"] {
    width: 100%;
  }
  #username {
    display: none;
  }

  & .form input[type="submit"] {
    text-align: center;
    padding: 16px 0;
    cursor: pointer;
    background: #298eea;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    border: none;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }
`;

const ForgotPassword = (props) => {
  const { code } = useParams();
  console.log(code);
  const onSubmit = (e) => {
    e.preventDefault();
    const email = document.getElementById("forgot-email").value;
    const username = document.getElementById("username").value;
    if (username) {
      Swal.fire("Error", "Please try again later.", "error");
      return;
    }
    console.log(email);
    Axios.post("https://admin.nouwall.to/auth/forgot-password", {
      email,
      url: "https://admin.nouwall.to/admin/plugins/users-permissions/auth/reset-password",
    })
      .then((response) => {
        Swal.fire(
          "Sent",
          "Please check your email for reset password link.",
          "success"
        );
      })
      .catch((error) => {
        Swal.fire("Error", "Please try again later.", "error");
        console.log(error);
      });
  };

  const onChangePassword = (e) => {
    e.preventDefault();
    const password = document.getElementById("password").value;
    const confirmPassword = document.getElementById("confirmPassword").value;
    if (!password || !confirmPassword) {
      Swal.fire("Error", "Please fill both fields.", "error");
      return;
    }
    Axios.post("https://admin.nouwall.to/auth/reset-password", {
      code, // code contained in the reset link of step 3.
      password,
      passwordConfirmation: confirmPassword,
    })
      .then((response) => {
        Swal.fire("Success", "Password has been changed.", "success");
      })
      .catch((error) => {
        Swal.fire("Error", "Please try again later.", "error");
        console.log(error);
      });
  };

  return (
    <MainWrapper>
      {!code && (
        <InnerContainer>
          <h1>Forgot password</h1>
          <form className="form" method="post" onSubmit={onSubmit}>
            <p className="fieldset">
              <label className="image-replace email" htmlFor="forgot-email">
                <i className="fas fa-envelope" />
              </label>
              <input
                className="full-width has-padding has-border"
                id="forgot-email"
                nameName="email"
                type="email"
                placeholder="Email"
              />
            </p>
            <p className="fieldset">
              <input name="username" id="username" />
              <input
                type="submit"
                className="watch-this-button sendit"
                value="Reset password"
              />
            </p>
          </form>
        </InnerContainer>
      )}
      {code && (
        <InnerContainer>
          <h1>Reset password</h1>
          <form className="form" method="post" onSubmit={onChangePassword}>
            <p className="fieldset">
              <label className="image-replace email" htmlFor="password">
                <i className="fas fa-key" />
              </label>
              <input
                className="full-width has-padding has-border"
                id="password"
                nameName="password"
                type="password"
                placeholder="Password"
              />
            </p>
            <p className="fieldset">
              <label className="image-replace email" htmlFor="confirmPassword">
                <i className="fas fa-key" />
              </label>
              <input
                className="full-width has-padding has-border"
                id="confirmPassword"
                nameName="password"
                type="password"
                placeholder="Confirm Password"
              />
            </p>
            <p className="fieldset">
              <input name="username" id="username" />
              <input
                type="submit"
                className="watch-this-button sendit"
                value="Reset password"
              />
            </p>
          </form>
        </InnerContainer>
      )}
    </MainWrapper>
  );
};

export default ForgotPassword;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkUser } from '../redux/actions/user.actions';
import styled from '@emotion/styled';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { checkNote } from '../redux/actions/note.actions';

const SpinnerWrapper = styled.div`

 & i {
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
 }
 @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
`;

const IsAuth = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [check, setCheck] = useState();
  const [allowNonSigned, setAllowNonSigned] = useState(true);
  const user = useSelector(redux => redux.user);

  const checkAllowNonSigned = () => {
    dispatch(checkNote());
    if (localStorage.getItem('token')) return;
    
    axios.get('/api/settings')
    .then((res) => {
      if (res.status === 400) {
        setCheck(true);
        setAllowNonSigned(false);
        if (location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/forgot-password' || location.pathname === '/auth') return;
        history.push('/login');
      }
      setCheck(true);
    })
    .catch(() => {
      setCheck(true);
      setAllowNonSigned(false);
      if (location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/forgot-password') return;
      history.push('/login');
    }
    )
  }
  const performCheck = async () => {
    if (localStorage.getItem('token')) {
     const user = await dispatch(checkUser());
     setCheck(true);
    } else {
      checkAllowNonSigned();
    }
  }
  useEffect(() => {
    if (!check) performCheck();
  },[check])

  useEffect(() => {
    if (!allowNonSigned && !user) {
      if (location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/forgot-password') {
        history.push('/login');
      }
    }
  }, [allowNonSigned]);

  useEffect(() => {
    checkAllowNonSigned();
  }, [location.pathname])

  if(check) return null;
  return (
    <div style={{
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,1)',
      zIndex:10000,
    }}>
      <SpinnerWrapper style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        backgroundColor: '#272727',
        width: '200px',
        height: '200px',
        borderRadius: '10px',
        boxShadow: 'rgba(255,255,255,0.2) 0 2px 14px 0',
      }}>
                      <i className="fas fa-spinner" style={{
                      color: 'rgb(41,142,234)',
                      fontSize: '80px',
                      marginTop: '60px',
                      }}/>
      </SpinnerWrapper>
    </div>
  )
}

export default IsAuth;

import React, { useState } from "react";
import styled from "@emotion/styled";
import CountUp from "react-countup";
import { useSelector } from "react-redux";

const Count = styled.div`
  body {
    font-size: 13px;
    color: #666;
    position: relative;
    -webkit-font-smoothing: antialiased;
    margin: 0;
  }

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  body,
  div,
  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  form,
  p,
  blockquote,
  th,
  td {
    margin: 0;
    padding: 0;
    font-size: 13px;
    direction: ltr;
  }

  .sectionClass {
    padding: 20px 0px 20px 0px;
    position: relative;
    display: block;
  }

  .fullWidth {
    width: 100% !important;
    display: table;
    float: none;
    padding: 0;
    min-height: 1px;
    height: 100%;
    position: relative;
  }

  .sectiontitle {
    background-position: center;
    margin: 10px 0 0px;
    text-align: center;
    min-height: 20px;
  }

  .sectiontitle h2 {
    font-size: 30px;
    color: #222;
    margin-bottom: 0px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .headerLine {
    width: 160px;
    height: 2px;
    display: inline-block;
    background: #101f2e;
  }

  .projectFactsWrap {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
  }

  #projectFacts .fullWidth {
    padding: 0;
  }

  .projectFactsWrap .item {
    width: 33.33%;
    padding: 10px 0px;
    text-align: center;
  }

  .projectFactsWrap .item:nth-child(1) {
    background: rgb(16, 31, 46);
  }

  .projectFactsWrap .item:nth-child(2) {
    background: rgb(18, 34, 51);
  }

  .projectFactsWrap .item:nth-child(3) {
    background: rgb(21, 38, 56);
  }

  .projectFactsWrap .item:nth-child(4) {
    background: rgb(23, 44, 66);
  }

  .projectFactsWrap .item p.number {
    font-size: 30px;
    padding: 0;
    font-weight: bold;
    height: 40px;
  }

  .projectFactsWrap .item p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    margin: 0;
    padding: 10px;
  }

  .projectFactsWrap .item > span {
    width: 60px;
    background: rgba(255, 255, 255, 0.8);
    height: 2px;
    display: block;
    margin: 0 auto;
  }

  .projectFactsWrap .item i {
    vertical-align: middle;
    font-size: 40px;
    color: rgba(255, 255, 255, 0.8);
  }

  .projectFactsWrap .item:hover i,
  .projectFactsWrap .item:hover p {
    color: white;
  }

  .projectFactsWrap .item:hover > span {
    background: white;
  }

  @media (max-width: 786px) {
    .projectFactsWrap .item {
      flex-grow: 1;
    }
  }

  .about-me-img {
    width: 120px;
    height: 120px;
    left: 10px;
    /* bottom: 30px; */
    position: relative;
    border-radius: 100px;
  }

  .about-me-img img {
  }

  .authorWindow {
    width: 600px;
    background: #75439a;
    padding: 22px 20px 22px 20px;
    border-radius: 5px;
    overflow: hidden;
  }

  .authorWindowWrapper {
    display: none;
    left: 110px;
    top: 0;
    padding-left: 25px;
    position: absolute;
  }

  .trans {
    opacity: 1;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    transition: all 500ms ease;
  }

  @media screen and (max-width: 768px) {
    .authorWindow {
      width: 210px;
    }

    .authorWindowWrapper {
      bottom: -170px;
      margin-bottom: 20px;
    }
  }
`;

const isPremium = (user) => {
  if (!user.premiumUntil) return false;
  const premium = new Date(user.premiumUntil);
  const today = new Date();
  if (premium.getTime() > today.getTime()) {
    return true;
  }
  return false;
};

const isPremiumText = (user) => {
  if (!user) return false;
  if (user && !user.premiumUntil) return false;
  const date_future = new Date(user.premiumUntil);
  const date_now = new Date();
  if (date_future.getTime() - date_now.getTime() >= 0) {
    let seconds = Math.floor((date_future - date_now) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    let days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
    return `${days} days ${hours} hours`;
  } else {
    return false;
  }
};

const Counters = () => {
  const counter = useSelector((redux) => redux.statistics);
  const user = useSelector((redux) => redux.user);
  if (!counter || (!counter.movies && !counter.users && !counter.series))
    return null;
  return (
    <Count>
      <div id="projectFacts" className="sectionClass">
        <div className="fullWidth eight columns">
          <div className="projectFactsWrap ">
            {user && user.username && (
              <div className="item wow fadeInUpBig animated animated">
                <i className="fa fa-user"></i>
                <p id="number3" className="number">
                  {user.name ? user.name : user.username.split("@")[0]}
                </p>
                <span></span>
                <p>
                  {isPremium(user) ? "Premium" : "Free Plan"}
                  {isPremium(user) && ` (${isPremiumText(user)})`}
                </p>
              </div>
            )}

            <div className="item wow fadeInUpBig animated animated">
              <i className="fa fa-film"></i>
              <p id="number1" className="number">
                <CountUp end={counter.movies} separator="," />
              </p>
              <span></span>
              <p>Total Movies</p>
            </div>
            <div className="item wow fadeInUpBig animated animated">
              <i className="fa fa-tv"></i>
              <p id="number2" className="number">
                <CountUp end={counter.series} separator="," />
              </p>
              <span></span>
              <p>Total TV Episodes</p>
            </div>
          </div>
        </div>
      </div>
    </Count>
  );
};

export default Counters;

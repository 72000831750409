import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { pause } from 'video-react/lib/actions/player';



const DownloadButton = (props) => {
  const user = useSelector(redux => redux.user);
  const history = useHistory();
  const { cdnUrl } = useSelector(redux => redux.statistics);
  const ref2 = props.ref2;

  const isPremium = () => {
    if (!user) return false;
    if (user && !user.premiumUntil) return false;
    const until = new Date(user.premiumUntil).getTime();
    const today = Date.now();
    if (until - today >= 0) {
      return true;
    }
  }

  const handleClickToDownload =(quality) => {
    if (!user || !user.username) {
      Swal.fire({
        title:'Error',
        text: 'You need to login in order to watch a movie',
        type: 'error',
        confirmButtonText: 'Login'        
      }).then(()=> history.push('/login'), ()=> false);
      return;
    } else {
      if (!user.freeTimeUntil) {

      } else {
        const hasWatch = new Date(user.freeTimeUntil);
        if (!isPremium() && hasWatch.getTime() > Date.now() && user.timeLeft <= 0) {
          props.ref2.current.player.pause();
          Swal.fire({
            title:'Error',
            text: 'You need to be premium member in order to watch more movies this month',
            type: 'error',
            confirmButtonText: 'Become Premium'        
          }).then(()=> history.push('/premium'), ()=> false);
          return;
        }
      }
    }
    if (props.serie) {
      props.getDownload(quality);
      //props.getWatchingVideo(props.movie);
    }
  }
  let activeLink = 'https://s3.wasabisys.com/s1account/';

  return (
    <>
    { isPremium() && (
      <>
      {props.serie.s1Video720 && (
        <li id="downloadButton720">
          <a href={`${activeLink}${props.serie.s1Video720}`} target="_blank" download>
        <div>
          <i
            className="fas fa-download"
            style={{
              fontSize: '12px',
              verticalAlign: 'middle',
              width: '48px'
            }}
          />
          Download 720p
        </div>
        </a>
      </li>
      )}
            {props.serie.s1Video1080 && (
        <li id="downloadButton1080">
                    <a href={`${activeLink}${props.serie.s1Video1080}`} target="_blank" download>
                    <div>
          <i
            className="fas fa-download"
            style={{
              fontSize: '12px',
              verticalAlign: 'middle',
              width: '48px'
            }}
          />
          Download 1080p
        </div>
        </a>
      </li>
      )}
</>
    )}
</>
  )
};


export default React.memo(DownloadButton);

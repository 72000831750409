import React, { useState} from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import Swal from 'sweetalert2';

const MainWrapper = styled.div`
position: relative;
color:white;
display: block;
vertical-align: top;
background: #1b1b1b;
height:100%;
min-height: calc(100vh - 70px);
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
margin: 70px 0 0 100px;
z-index: 1;
`;

const SectionWrapper = styled.section`
padding: 30px;
`;

const Header = styled.h1`
font-size: 40px;
font-weight: 300;
line-height: 1.5em;
`;

const SubHeader = styled.h3`
font-size: 24px;
font-weight: 300;
margin-bottom: 30px;
line-height: 1.5em;
`;

const FormWrapper = styled.form`
max-width: 600px;
width: 100%;
margin: 20px auto 0 auto;
text-align: left;
padding: 20px;

& input[type=text]:focus{
  background: rgba(163, 163, 163, 0.09);
}

& input[type=text] {
  background: rgba(163, 163, 163, 0.07);
  padding: 15px 20px 15px 50px;
  border: none;
  outline: none;
  width:100%;
  color: white;
}
& input {
  border-radius: 3px;
  font-size: 16px;
}

& p {
  position:relative;
  margin-bottom: 20px;
}

& label {
  position:absolute;
  left:0;
  top:0;
  color: #666;
}

& label i {
  position: absolute;
  top: 15px;
  left: 20px;
}

& input[type=text]:focus + label, & textarea:focus + label {
  color: #fff;
}

& textarea {
  width: 100%;
  height: 200px;
  color: #fff;
  background: rgba(163, 163, 163, 0.07);
  padding: 15px 20px 15px 50px;
  border: none;
  outline: none;
  font-size: 16px;
  min-height: 50px;
  max-width: 560px;
  min-width: 200px;
}
`;

const EmailWrapper = styled.p`
& input {
  color: black !important;
  background: white !important;
}

& input[type=text]:focus + label, & textarea:focus + label {
  color: #666 !important;
}
`;

const Select = styled.select`
position: relative;
color: rgba(255,255,255,.7);
background: #414141;
height: 46px;
padding: 0 40px 0 20px;
border: none;
outline: none;
border-radius: 3px;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
font-size: 16px;
`;

const Button = styled.button`
text-align: center;
padding: 16px 25px;
cursor: pointer;
background: #298eea;
color: #FFF;
font-size: 14px;
font-weight: bold;
border: none;
border-radius: 3px;
-webkit-appearance: none;
-moz-appearance: none;
-ms-appearance: none;
-o-appearance: none;
appearance: none;
`;

const Disclaimer = styled.div`
max-width: 80%;
color: rgba(255,255,255,.7);
padding: 40px 30px;
margin: auto;
border-top: 1px solid rgba(255,255,255,.1);
text-align: left;
margin-top: 70px;
font-size: 16px;

& h4 {
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 400;
}
& p {
  margin-bottom: 20px;
}

& ul {
  list-style-type: none;
  margin-bottom: 20px;
}
& ul > li {
  text-indent: 0px;
  font-size: 14px;
}
& ul > li:before {
  content: "-";
  padding-right:10px;
}
`;

const Contact = (props) => {
  const [form, setForm] = useState({});
  const [disable, setDisabled] = useState();

  const handleForm = (e) => {
    e.preventDefault();
    setDisabled(false);
    axios.post('/api/contact', {
      ...form
    })
    .then(()=> Swal.fire('Success', 'Thank you for your message.', 'success'))
    .catch(() => Swal.fire('Error', 'There was an error with your message. Please try again later.', 'error'));
  };

  return (
  <MainWrapper>
    <SectionWrapper>
      <Header>Contact</Header>
      <SubHeader>Submit a support ticket by filling in the form below</SubHeader>
      <FormWrapper>
        <p>
          <input id="name" name="name" type="text" placeholder="Name" onChange={(e) => setForm({...form, [e.target.name]: e.target.value })}/>
          <label htmlFor="name">
            <i className="fas fa-user fa-sm" />
          </label>
        </p>
        <EmailWrapper>
          <input id="email" name="email" type="text" placeholder="E-mail address"  onChange={(e) => setForm({...form, [e.target.name]: e.target.value })}/>
          <label htmlFor="email">
            <i className="fas fa-envelope fa-sm" />
          </label>
        </EmailWrapper>
        <p>
          <Select id="contact-type" name="type" required=""  onChange={(e) => setForm({...form, [e.target.name]: e.target.value })}>
            <option value="General support">General support</option>
            <option value="Premium">Premium</option>
            <option value="Bugs or Glitches">Bugs or Glitches</option>
            <option value="Dmca">Dmca</option>
            <option value="Other">Other</option>
          </Select>
          <i
            style={{
              position: 'absolute',
              left: '140px',
              top: '17px',
              pointerEvents: 'none',
            }}
            className="fas fa-chevron-down fa-xs"
          />
        </p>
        <p>
          <textarea
            id="contact-message"
            type="textarea"
            required=""
            name="message"
            placeholder="Message"
            onChange={(e) => setForm({...form, [e.target.name]: e.target.value })}/>
          <label>
            <i
              className="fas fa-comment-alt fa-xs"
              style={{
                position: 'absolute', top: '20px', left: '20px',
              }}
            />
          </label>
        </p>
        <input id="phone" name="phone" hidden onChange={(e) => setForm({...form, [e.target.name]: e.target.value })}/>
        <Button type="submit" onClick={(e) => handleForm(e)}>Send message</Button>
      </FormWrapper>
    </SectionWrapper>
    <Disclaimer>
      <h4>Copyright Infringement Policy</h4>
      <p>
      If you are a copyright owner or an agent thereof, and you believe that any content hosted on our website Wonulla (Wonulla.to) infringes your copyrights, then you may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing Wonulla's Designated Copyright Agent with the following information in writing (please consult your legal counsel or See 17 U.S.C. Section 512(c)(3) to confirm these requirements):
      </p>
      <ul>
        <li>
        A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.

        </li>
        <li>
          Identification of the copyrighted work claimed to have been infringed, or, if multiple
          copyrighted works at a single online site are covered by a single notification, a
          representative list of such works at that site.
        </li>
        <li>
        Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit Wonulla to locate the material. Providing URLs in the body of an email is the only way to locate those files. URLs have to be to a direct URL and not to an info/search page. URLs in every DMCA notice have to be no more than 10.
        </li>
        <li>
          Information reasonably sufficient to permit the service provider to contact the
          complaining party, such as an address, telephone number, and, if available, an
          electronic mail address at which the complaining party may be contacted.
        </li>
        <li>
          A statement that the complaining party has a good faith belief that use of the material
          in the manner complained of is not authorized by the copyright owner, its agent, or the
          law.
        </li>
        <li>
          A statement that the information in the notification is accurate, and under penalty of
          perjury, that the complaining party is authorized to act on behalf of the owner of an
          exclusive right that is allegedly infringed.
        </li>
        <li>
        A scanned physical document that proves you are or you are representing the copyright owner.
        </li>
        <li>
        A valid email address username@copyrightcompany will help you to prove you're representing or you're the legitimate copyright owner.
        </li>
        <li>
        We will not process requests from free mailboxes (Gmail, Hotmail, Yahoo, AOL, MailRu, Protonmail, etc).
        </li>
        <li>
        All received emails have to be in plain/text. Our automatic system is not capable of understanding anything else.
        </li>
        <li>
        Personal or attorneys notices won't be processed. They have to come from the company that believes it holds the copyright or the agent thereof.
        </li>
        <li>Respect our work and we'll respect yours. Threats wont do you any good.</li>
      </ul>
      <p>
      Please note that under Section 512(f) of the DMCA, any person who knowingly materially misrepresents that material or activity is infringing may be subject to liability.
      </p>
      <p>
      If you are writing from a country within the European Union, please send us a European Intellectual Property Rights Enforcement Directive request.
      </p>

    </Disclaimer>
  </MainWrapper>
);
};

export default Contact;

import axios from 'axios';
import { AUTH_USER } from './action.types';
import Swal from 'sweetalert2';
import { API_URL } from '../../config';

export const authUser = (form) => async (dispatch) => {
  const user = await axios.post('/api/login-2', form)
    .then((res) => res.data)
    .catch((e) => {
      if (e && e.response && e.response.status === 409) {
        Swal.fire('Error', 'There was an error. There are already 4 devices logged-in with this account.', 'error');
      } else {
        Swal.fire('Error', 'There was an error. Please check your email and password or that you have verified your account by following the instructions in the email you have received after registration.', 'error');
      }

      return false;
    }
  );

  if (user) {
    localStorage.setItem('token', user.token);
    dispatch({
      type: AUTH_USER,
      payload: user,
    });
    return true;
  }
};

export const logout = async () => {
  const token = localStorage.getItem('token');
  const logout = await axios.get('/api/logout?jwt='+token)
  .then(() => true)
  .catch(() => false);
  if (logout) {
      localStorage.removeItem('token');
      window.location.href = '/';
  } else {
    Swal.fire("Error", 'There as an error.', 'error');
  }
}

export const registerUser = (form) => async (dispatch) => {
  const user = await axios.post('/api/register', form)
    .then((res) => res.data)
    .catch((e) => {
      if (e && e.response) {
        if (e.response.status === 409) {
          Swal.fire('Error', 'There was an error. There are already 4 devices logged-in with this account.', 'error');
        } else if (e.response.status === 410) {
          Swal.fire('Error', 'Please login with your existing account.', 'error');
        }else if (e.response.status === 555) {
          Swal.fire('Error', 'Error with ReCaptcha.', 'error');
        }else if (e.response.status === 400) {
          Swal.fire('Error', 'Internal error with registration.', 'error');
        }
      } else {
        Swal.fire('Error', 'There was an error. Please check your email and password or try again later.', 'error');
      }

      return false;
    });
  if(user) {
    Swal.fire('Success','Your account has been created. We have emailed you instructions for activating your account.', 'success');
    return false;
  }
  console.log(user);
  if (user) {
    localStorage.setItem('token', user.token);
    dispatch({
      type: AUTH_USER,
      payload: user,
    });
    return true;
  }
};

export const checkUser = () => async (dispatch, getState) => {
  const user = await axios.post('/api/check-user')
    .then((res) => res.data)
    .catch(() => {Swal.fire('Error', 'There was an error. Please check your email and password or try again later.', 'error');return false;});
  if (user) {
    localStorage.setItem('token', user.token);
    if (window.location.pathname === '/premium') {
      if (user.premiumUntil !== getState().user.premiumUntil) {
        window.location.href = '/';
      }
    }

    dispatch({
      type: AUTH_USER,
      payload: user,
    });
    return true;
  }
  return false;
};

export const updateLastWatch = () => async (dispatch, getState) => {
  const now = new Date().toISOString().slice(0,10);
  axios({
    method: 'get',
    url: '/api/get-details',
    headers: {
      'Authorization': 'Bearer '+getState().user.token,
    }
  })
  .then(res => {
   if (res && res.data) {
    dispatch({
      type: AUTH_USER,
      payload: {...getState().user, timeLeft: res.data.timeLeft, freeTimeUntil: res.data.freeTimeUntil},
    })
   } 
  });

}

export default authUser;

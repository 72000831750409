import React, { useState, useLayoutEffect, useEffect } from 'react';
import styled from '@emotion/styled';
import ItemsCarousel from 'react-items-carousel';
import { useSelector } from 'react-redux';

import MovieItem from '../components/MovieItem';
import EpisodeItem from '../components/EpisodeItem';
import NewsWrapper from '../components/NewsWrapper';
import Counters from '../components/Counters';

const MainWrapper = styled.div`
position: relative;
color:white;
display: block;
vertical-align: top;
background: #1b1b1b;
height:100%;
min-height: calc(100vh - 70px);
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
margin: 70px 0 0 100px;
z-index: 1;
@media screen and (max-width: 770px) {
  margin-top: 80px;
}

& .itemsWrapper {
 width: 108%;
}

 .sliderWrapper {
   overflow: hidden;
 }
`;

const Button = styled.button`
border: none;
background: ${(props) => (props.right ? 'linear-gradient(270deg,rgba(27,27,27,1)0%, rgba(27,27,27,0) 100%)' : 'linear-gradient(90deg,rgba(27,27,27,1)0%, rgba(27,27,27,0) 100%)')};
height: calc(100% - 23px);
margin-top: -4px;
width: 50px;
color: white;
text-align: center;
font-size: 30px;
cursor: pointer;
outline:none;

&:hover {
  background-color: rgba(27,27,27,0.6);
}
`;

const SliderHeader = styled.h1`
padding-left: 50px;
color: white;
font-size: 21px;
text-align: left;
font-weight: 300;
margin: 15px 0;
`;

const movieItem = {
  title: 'Green Book',
  rating: 8.2,
  release_date: '2018-1-1',
  id: 343,
  poster: '/h70wRv6iWxiqED4orqfxcEl74Rc.jpg',
};

const episodeItem = {
  title: 'The Witcher',
  rating: 8.4,
  release_date: '2019-1-1',
  id: 343,
  poster: '/zrPpUlehQaBf8YX2NrVrKK8IEpf.jpg',
};

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const Home = (props) => {
  const [popularMoviesIndex, setPopularMoviesIndex] = useState(0);
  const [popularEpisodesIndex, setPopularEpisodesIndex] = useState(0);
  const [recentAddedIndex, setRecentAdded] = useState(0);
  const [recentEpisodeIndex, setrecentEpisodeIndex] = useState(0);
  const [topGrossIndex, settopGrossIndex] = useState(0);
  const [width, _height] = useWindowSize();
  const [nSlides, setNSliders] = useState(3);
  const [popularMovies, setPopularMovies] = useState([]);
  const [popularEpisodes, setPopularEpisodes] = useState([]);
  const [recent, setRecent] = useState([]);
  const [recentEpisodes, setRecentEpisodes] = useState([]);
  const [topGross, setTopGross] = useState([]);
  const frontPage = useSelector((redux) => redux.frontPage);

  useEffect(() => {
    const popM = (frontPage && frontPage.popularMovies) ? frontPage.popularMovies.map((item) => <MovieItem item={item} fullSize />) : [];
    const popE = (frontPage && frontPage.popularEpisodes) ? frontPage.popularEpisodes.map((item) => <EpisodeItem item={item} fullSize />) : [];
    const rec = (frontPage && frontPage.recent) ? frontPage.recent.map((item) => <MovieItem item={item} fullSize />) : [];
    const topG = (frontPage && frontPage.topGross) ? frontPage.topGross.map((item) => <MovieItem item={item} fullSize />) : [];
    const recEpisodes = (frontPage && frontPage.recentEpisodes) ? frontPage.recentEpisodes.map((item) => <EpisodeItem item={item} fullSize />) : [];
    setPopularMovies(popM);
    setPopularEpisodes(popE);
    setRecent(rec);
    setTopGross(topG);
    setRecentEpisodes(recEpisodes)
  }, [frontPage]);

  useEffect(() => {
    if (width <= 500) {
      setNSliders(3);
    }
    if (width > 500) {
      setNSliders(3);
    }
    if (width > 700) {
      setNSliders(4);
    }
    if (width > 900) {
      setNSliders(5);
    }
    if (width > 1050) {
      setNSliders(5);
    }
    if (width > 1200) {
      setNSliders(6);
    }
    if (width > 1400) {
      setNSliders(7);
    }
    if (width > 1550) {
      setNSliders(8);
    }
    if (width > 1700) {
      setNSliders(10);
    }
    if (width > 1920) {
      setNSliders(11);
    }
  }, [width]);

  return (
    <MainWrapper>
            <NewsWrapper/>
            <Counters/>
      <SliderHeader>
      <i className="fas fa-film" style={{marginRight: '20px'}}/>
        Popular Movies This Week
      </SliderHeader>
      <ItemsCarousel
        classes={{
          wrapper: 'sliderWrapper',
          itemsWrapper: 'itemsWrapper',
        }}
        infiniteLoop
        activePosition="center"
        numberOfCards={nSlides}
        children={popularMovies}
        showSlither
        firstAndLastGutter={false}
        slidesToScroll={3}
        firstAndLastGutter
        activeItemIndex={popularMoviesIndex}
        requestToChangeActive={setPopularMoviesIndex}
        rightChevron={(
          <Button right>
            <i className="fas fa-chevron-right" />
          </Button>
      )}

        leftChevron={(
          <Button>
            <i className="fas fa-chevron-left" />
          </Button>
      )}
      />
            <SliderHeader>
            <i className="fas fa-tv" style={{marginRight: '20px'}}/>
        Popular TV Shows This Week
      </SliderHeader>
      <ItemsCarousel
        classes={{
          wrapper: 'sliderWrapper',
          itemsWrapper: 'itemsWrapper',
        }}
        infiniteLoop
        activePosition="center"
        numberOfCards={nSlides}
        children={popularEpisodes}
        showSlither
        firstAndLastGutter={false}
        slidesToScroll={3}
        firstAndLastGutter
        activeItemIndex={popularEpisodesIndex}
        requestToChangeActive={setPopularEpisodesIndex}
        rightChevron={(
          <Button right>
            <i className="fas fa-chevron-right" />
          </Button>
      )}

        leftChevron={(
          <Button>
            <i className="fas fa-chevron-left" />
          </Button>
      )}
      />
      <SliderHeader>
        Recently Added
      </SliderHeader>
      <ItemsCarousel
        infiniteLoop
        classes={{
          wrapper: 'sliderWrapper',
          itemsWrapper: 'itemsWrapper',
        }}
        activePosition="center"
        numberOfCards={nSlides}
        children={recent}
        showSlither
        slidesToScroll={3}
        firstAndLastGutter
        activeItemIndex={recentAddedIndex}
        requestToChangeActive={setRecentAdded}
        rightChevron={(
          <Button right>
            <i className="fas fa-chevron-right" />
          </Button>
      )}

        leftChevron={(
          <Button>
            <i className="fas fa-chevron-left" />
          </Button>
      )}
      />
      <SliderHeader>
        Recent Episodes
      </SliderHeader>
      <ItemsCarousel
        infiniteLoop
        classes={{
          wrapper: 'sliderWrapper',
          itemsWrapper: 'itemsWrapper',
        }}
        activePosition="center"
        numberOfCards={nSlides}
        children={recentEpisodes}
        showSlither
        slidesToScroll={3}
        firstAndLastGutter
        activeItemIndex={recentEpisodeIndex}
        requestToChangeActive={setrecentEpisodeIndex}
        rightChevron={(
          <Button right>
            <i className="fas fa-chevron-right" />
          </Button>
      )}

        leftChevron={(
          <Button>
            <i className="fas fa-chevron-left" />
          </Button>
      )}
      />
      <SliderHeader>
        Movies Worth Watching
      </SliderHeader>
      <ItemsCarousel
        classes={{
          wrapper: 'sliderWrapper',
          itemsWrapper: 'itemsWrapper',
        }}
        infiniteLoop
        activePosition="center"
        numberOfCards={nSlides}
        children={topGross}
        showSlither
        slidesToScroll={3}
        firstAndLastGutter
        activeItemIndex={topGrossIndex}
        requestToChangeActive={settopGrossIndex}
        rightChevron={(
          <Button right>
            <i className="fas fa-chevron-right" />
          </Button>
      )}

        leftChevron={(
          <Button>
            <i className="fas fa-chevron-left" />
          </Button>
      )}
      />
    </MainWrapper>
  );
};

export default Home;

import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import MovieItem from '../components/MovieItem';
import getMovies from '../redux/actions/movies.actions';
import NewsWrapper from '../components/NewsWrapper';


const MainWrapper = styled.div`
position: relative;
color:white;
display: block;
vertical-align: top;
background: #1b1b1b;
height:100%;
min-height: calc(100vh - 70px);
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
margin: 70px 0 0 100px;
z-index: 1;
`;

const SectionWrapper = styled.section`
padding: 10px 20px;
`;

const Pagination = styled.nav`
margin: 60px 0;

& ul{
  text-align: center;
}

& ul li{
  font-size: 16px;
  display: inline-block;
  padding: 0 5px;
}

& ul li button{
  cursor:pointer;
  border: none;
  display: inline-block;
  color: rgba(255,255,255,.7);
  background: rgba(255, 255, 255, 0.12);
  height: 50px;
  line-height: 50px;
  padding: 0 23px;
  border-radius: 30px;
  transition: .3s ease;
  text-decoration: none;
}

& ul li button:hover {
  color: rgba(255,255,255,.85);
  background: rgba(255,255,255,.3);
}

& ul li button.current {
  color: #fff;
  background: #298eea;
}
`;
/*
const movies = [];
for (let i = 0; i < 60; i += 1) {
  movies.push({
    title: 'Green Book',
    rating: 8.2,
    release_date: '2018-1-1',
    id: 343,
    poster_path: 'https://image.tmdb.org/t/p/w342/h70wRv6iWxiqED4orqfxcEl74Rc.jpg',

  });
} */


const Movies = () => {
  const movies = useSelector((redux) => redux.movies).slice(0,40);
  const moviesLength = useSelector((redux) => redux.movies).length;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [startsWith, setStartsWith]= useState(searchParams.get("startsWith"));
  const [startsAt, setStartsAt] = useState(searchParams.get("startsAt"));
  const [sortBy, setSortBy] = useState(searchParams.get("sortBy"));
  const [genre, setGenre] = useState(searchParams.get("genres") ? searchParams.get("genres").split(',').filter(item => item!== '') : undefined);
  const dispatch = useDispatch();
  const history = useHistory();

  const retrieveMovies = () => {
    dispatch(getMovies(startsWith, genre, sortBy, startsAt));
  };

  useEffect(() => {
    if (searchParams.has("startsWith") && searchParams.get("startsWith") !== startsWith) {
      setStartsWith(searchParams.get("startsWith"))
    }
    if (searchParams.has("startsAt") && searchParams.get("startsAt") !== startsAt) {
      setStartsAt(searchParams.get("startsAt"))
    }
    if (searchParams.has("sortBy") && searchParams.get("sortBy") !== sortBy) {
      setSortBy(searchParams.get("sortBy"))
    }
    if (searchParams.has("genres") && searchParams.get("genres") !== genre) {
      setGenre(searchParams.get("genres").split(',').filter(item => item!== ''))
    }
    if (!searchParams.has("startsWith")) {
      setStartsWith();
    }
    if (!searchParams.has("startsAt")) {

      setStartsAt();
    }
    if (!searchParams.has("sortBy")) {
      setSortBy();
    }
    if (!searchParams.has("genres")) {
      setGenre();
    }
  }, [location])

useEffect(() => {
  window.scrollTo(0,0);
  const update = {};
  if (genre) update.genres = genre.join();
  if (!startsWith) delete update.startsWith;
  if (!startsAt) delete update.startsAt;
  if (!sortBy) delete update.sortBy;
  if (!genre || genre.length ===0) delete update.genres;
  if (startsWith) update.startsWith = startsWith;
  if (startsAt) update.startsAt = startsAt;
  if (sortBy) update.sortBy = sortBy;
  history.push({
    search: "?"+ new URLSearchParams(update).toString(),
  })
}, [startsAt])
  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount(count + 1);
    if (count === 1) return;
    retrieveMovies();
  }, [startsWith, sortBy, genre, startsAt]);
  return (
    <MainWrapper>
      <SectionWrapper>
      <NewsWrapper/>
      {movies.map((item, index) => {
      return <MovieItem item={item} key={item.title}/>})}
        <Pagination>
          <ul>
            {(startsAt && startsAt !== '0' && startsAt !== '1') && (
            <li className="button"><button onClick={() =>setStartsAt(Number(startsAt)-1)} data-page="" data-type="prev" >Prev</button></li>
            )}
            {(startsAt && Number(startsAt) > 3) && (
              <>
            <li className="button"><button onClick={() =>setStartsAt(1)} data-page="" data-type="prev" >1</button></li>...</>)}
                                    {(startsAt && Number(startsAt) > 2) && (
            <li className="button"><button onClick={() =>setStartsAt(Number(startsAt)-2)} data-page="" data-type="prev" >{Number(startsAt)-2}</button></li>)}
            {(startsAt && Number(startsAt) > 1) && (
            <li className="button"><button onClick={() =>setStartsAt(Number(startsAt)-1)} data-page="" data-type="prev" >{Number(startsAt)-1}</button></li>
            )}
             {((movies && moviesLength > 40 && (!startsAt || startsAt === '1' || startsAt === '0')) || (startsAt && startsAt !=='1' && startsAt !=='0')) && (
            <li><button className="current first num" data-page="1" >{Number(startsAt) || 1}</button></li>
            )}
            { movies && moviesLength > 40 && (
              <>
            <li className="button"><button onClick={() =>setStartsAt(startsAt ?Number(startsAt)+1 : 2)} data-page="" data-type="prev" >{startsAt ? Number(startsAt)+1 : 2}</button></li>
            </>
            )}
                        { movies && moviesLength > 80 && (
              <>
            <li className="button"><button onClick={() =>setStartsAt(startsAt ?Number(startsAt)+2 : 3)} data-page="" data-type="prev" >{startsAt ? Number(startsAt)+2 : 3}</button></li>
            </>
            )}
            { movies && moviesLength > 40 && (
              <>
            <li className="button"><button onClick={() =>setStartsAt(startsAt ?Number(startsAt)+1 : 2)} data-page="5" data-type="next" >Next</button></li>
            </>
            )}
          </ul>
        </Pagination>
      </SectionWrapper>
    </MainWrapper>
  );
};

export default Movies;

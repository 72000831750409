import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import { useLocation, useHistory, Link } from "react-router-dom";
import { logout } from "../redux/actions/user.actions";
import axios from "axios";

import logo from "../img/logo.png";
import Swal from "sweetalert2";

const HeaderWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0px;
  width: 100%;
  height: 70px;
  line-height: 50px;
  padding: 0;
  z-index: 30;
  background: #272727;
  padding: 9px 10px 9px 130px;
  color: white;
  .search li:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
  @media only screen and (max-width: 770px) {
    display: flex;
    justify-content: space-between;
  }
`;

const Logo = styled.div`
  position: absolute;
  left: 10px;
  font-weight: 800;
  font-size: 22px;
`;

const Filters = styled.ul`
  line-height: 1;
  float: left;
  list-style: none;

  & li {
    position: relative;
    display: inline-block;
    background: rgba(35, 35, 35, 0);
    line-height: 30px;
    margin: 10px 10px 10px 0;
    border-radius: 3px;
    vertical-align: top;
    font-size: 14px;
    @media screen and (max-width: 770px) {
      display: none;
    }
  }

  @media only screen and (max-width: 550px) {
    .search {
      width: 100px;
    }
    form {
      padding-left: 0 !important;
    }
    #search {
      width: 70px;
    }
  }

  .search {
    display: inline-block;
  }

  & li.dropdown.abc-filter.open {
    border-color: #66aa33 !important;
    color: #fff !important;
  }

  & li.dropdown.abc-filter.open i {
    transform: rotate(180deg);
  }

  & .dropdown {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }

  & li .dropdown-toggle {
    color: rgba(255, 255, 255, 0.7);
    display: inline-block;
    padding: 0 15px;
    cursor: pointer;
  }

  & li.dropdown.open > ul {
    display: block;
    margin-top: 20px;
    padding: 15px;
    background: #141414;
    border-radius: 0 0 3px 3px;
    box-sizing: content-box;
  }

  & li.open.abc-filter ul {
    width: 360px;
  }

  & li .dropdown-toggle .value {
    display: inline-block;
    text-transform: capitalize;
    padding-left: 10px;
    color: #fff;
    padding-right: 5px;
  }

  & li .dropdown-toggle i {
    color: #fff;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 10px;
  }

  & .dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
  }

  & .dropdown-menu li {
    margin: 0;
  }

  & li.open.abc-filter ul li {
    float: left;
    width: 40px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    padding: 0;
    border: none;
  }

  & ul li {
    display: block;
    padding: 0 15px;
    line-height: 35px;
    color: rgba(255, 255, 255, 0.7);
    background: #141414;
    white-space: nowrap;
    cursor: pointer;
    font-size: 14px;
  }

  & li.dropdown.genre-filter.open {
    border-color: #66aa33 !important;
    color: #fff !important;
  }

  & li.dropdown.open > ul.dropdown-menu-large {
    width: 500px;
    background: #141414;
    padding: 15px;
    box-sizing: content-box;
    border-radius: 0px 0px 3px 3px;
  }

  & li.dropdown.open > ul {
    display: block;
    margin-top: 20px;
  }

  & li.dropdown.open > ul.dropdown-menu-large li {
    display: inline-block;
    width: calc(100% / 3);
    line-height: 1;
    padding: 0;
  }

  & ul li:first-child {
    border: none;
  }

  & ul li {
    color: rgba(255, 255, 255, 0.7);
    background: #141414;
    white-space: nowrap;
    cursor: pointer;
    font-size: 14px;
  }

  &
    li.dropdown.open
    > ul.dropdown-menu-large
    li
    input[type="checkbox"]
    + label {
    display: block;
    line-height: 35px;
    padding: 0 15px 0 35px;
  }

  & ul li input[type="checkbox"] + label {
    position: relative;
    cursor: pointer;
  }

  & ul li.dropdown.open > ul.dropdown-menu-large {
    width: 500px;
    background: #141414;
    padding: 15px;
    box-sizing: content-box;
    border-radius: 0px 0px 3px 3px;
  }

  & ul li.dropdown.open > ul.dropdown-menu-large li {
    display: inline-block;
    width: calc(100% / 3);
    line-height: 1;
    padding: 0;
  }

  & ul li input[type="checkbox"] {
    display: none;
  }

  &
    li.dropdown.open
    > ul.dropdown-menu-large
    li
    input[type="checkbox"]
    + label:before {
    content: "";
    position: absolute;
    left: 15px;
    top: 50%;
    margin-top: -5px;
    width: 10px;
    height: 10px;
    background: #1f1f1f;
    border-radius: 2px;
  }

  li.dropdown.open
    > ul.dropdown-menu-large
    li
    input[type="checkbox"]:checked
    + label:after {
    border-radius: 3px;
    content: "";
    width: 10px;
    height: 10px;
    background-color: #2ecc71;
    line-height: 1;
    color: #2ecc71;
    position: absolute;
    left: 14px;
    top: 50%;
    margin-top: -4px;
  }

  &
    li.dropdown.genre-filter.open
    > ul.dropdown-menu-large
    li
    input[type="checkbox"]
    + label {
    text-align: left;
  }

  & li.dropdown.sortby-filter.open > ul.dropdown-menu li {
    text-align: left;
  }

  & ul li form {
    padding: 0 15px;
  }

  & li.search form {
    background-color: rgba(31, 112, 185, 0);
    border-radius: 3px;
  }

  & li form i {
    padding-right: 10px;
  }

  & li form input {
    color: #fff;
    border: none;
    outline: none;
    background: none;
    -webkit-appearance: none;
    font-size: 16px;
    font-weight: 400;
  }

  & li form input::placeholder {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
  }

  & li form input:focus::placeholder {
    color: gray;
    font-weight: 300;
  }

  & .auto-complete-container {
    overflow: auto;
    width: 278px;
    margin-top: 18px;
  }
`;

const HeaderNav = styled.ul`
  float: right !important;
  list-style: none;

  & li {
    position: relative;
    display: inline-block;
    background: rgba(35, 35, 35, 0);
    line-height: 30px;
    margin: 10px 10px 10px 0;
    border-radius: 3px;
    vertical-align: top;
    font-size: 14px;
  }

  & li.user-normal.dropdown {
    border: 0px !important;
    padding: 0px !important;
    width: auto !important;
  }

  & li a {
    color: #ffffff;
    text-decoration: none;
    transition: 0.3s ease;
  }

  & li .dropdown-toggle {
    color: rgba(255, 255, 255, 0.7);
    display: inline-block;
    padding: 0 15px;
    cursor: pointer;
  }

  & li.user-normal.dropdown .dropdown-toggle {
    width: 129px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & .center-text {
    text-align: center;
  }

  & .btn-alt {
    font-size: 14px;
    border: 0px solid rgba(255, 255, 255, 0.7);
    border-radius: 3px;
  }

  & li .dropdown-toggle i {
    color: #fff;
    padding-left: 5px;
    padding-right: 5px;
  }

  & li.user-normal.dropdown .dropdown-toggle {
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.2);
  }

  & li.dropdown.open > ul {
    display: block;
    padding: 0 15px;
    background: #141414;
    border-radius: 0 0 3px 3px;
    box-sizing: content-box;
  }

  & li.dropdown > ul {
    display: none;
  }

  & ul li:first-child {
    border: none;
  }

  & ul li {
    display: block;
    padding: 0 15px;
    line-height: 35px;
    color: rgba(255, 255, 255, 0.7);
    background: #141414;
    white-space: nowrap;
    cursor: pointer;
  }

  & li ul li a {
    color: rgba(255, 255, 255, 0.7);
    display: block;
    text-decoration: none;
    transition: 0.3s ease;
  }

  & .btn-alt:hover {
    background: rgba(255, 255, 255, 0.1) !important;
    border-color: #fff;
  }

  & li i {
    padding-right: 5px;
  }

  & ul li:hover {
    color: #fff;
    background: rgba(7, 7, 7, 0.97);
  }
  .hamburger {
    display: none;
    margin-right: 0;
  }
  @media screen and (max-width: 770px) {
    li.user-normal.dropdown {
      width: 50px !important;
    }
    .dropdown-toggle {
      width: 60px !important;
      padding-left: 2px;
      padding-right: 2px;
    }
    li {
      display: none;
    }
    .hamburger {
      display: block;
    }
  }
`;

const savePayment = (query) => {
  if (query.indexOf("status=fail") !== -1) {
    Swal.fire(
      "Error",
      "Payment failed. Please try again or contact us",
      "error"
    );
    return;
  }
  if (query.indexOf("status=success") > -1) {
    const searchParams = new URLSearchParams(query);
    axios
      .post("/api/transaction", {
        order_id: searchParams.get("order_id"),
        transactionId: searchParams.get("sulte_apt_no"),
      })
      .then(() => Swal.fire("Thank you for your payment."))
      .catch((err) => {
        console.log(err);
        Swal.fire("Error", "There was an error please contact us", "error");
      });
  }
};

const Header = (props) => {
  const user = useSelector((redux) => redux.user);
  const statistics = useSelector((redux) => redux.statistics);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [open, setOpen] = useState();
  const [abc, setABC] = useState(searchParams.get("startsWith"));
  const [genres, setGenres] = useState(
    searchParams.get("genres")
      ? searchParams
          .get("genres")
          .split(",")
          .filter((item) => item !== "")
      : undefined
  );
  const [startsAt, setStartsAt] = useState(searchParams.get("startsAt"));
  const [sort, setSort] = useState(searchParams.get("sortBy"));
  const [showFilters, setShowFilters] = useState();
  const [search, setSearch] = useState();
  const [searchedFilms, setSearchedFilms] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [mobileMenu, setMobileMenu] = useState();

  useEffect(() => {
    if (search && search.length > 2) {
      axios.get("/api/search?search=" + search).then((res) => {
        setShowSearchResults(true);
        setSearchedFilms(res.data);
      });
    }
  }, [search]);

  useEffect(() => {
    if (location.pathname === "/premium") return;
    if (location.pathname === "/search") return;
    if (location.pathname === "/register") return;
    if (location.pathname.split("/")[1] === "serie") return;
    savePayment(location.search);
    if (
      searchParams.has("startsWith") &&
      searchParams.get("startsWith") !== abc
    ) {
      setABC(searchParams.get("startsWith"));
    }
    if (
      searchParams.has("startsAt") &&
      searchParams.get("startsAt") !== startsAt
    ) {
      setStartsAt(searchParams.get("startsAt"));
    }
    if (searchParams.has("sortBy") && searchParams.get("sortBy") !== sort) {
      setSort(searchParams.get("sortBy"));
    }
    if (searchParams.has("genres") && searchParams.get("genres") !== genres) {
      const filtered = searchParams
        .get("genres")
        .split(",")
        .filter((item) => item !== "");
      setGenres(filtered);
    }
    return () => {
      setGenres();
      setSort();
      setABC();
      setStartsAt();
    };
  }, [location.pathname]);

  useEffect(() => {
    const update = {};
    if (location.pathname === "/premium") return;
    if (location.pathname === "/search") return;
    if (location.pathname === "/register") return;
    if (location.pathname.split("/")[1] === "serie") return;
    if (genres) update.genres = genres.join();
    if (!abc) delete update.startsWith;
    if (!startsAt) delete update.startsAt;
    if (!sort) delete update.sortBy;
    if (!genres || genres.length === 0) delete update.genres;
    if (abc) update.startsWith = abc;
    if (startsAt) update.startsAt = startsAt;
    if (sort) update.sortBy = sort;
    history.push({
      search: "?" + new URLSearchParams(update).toString(),
    });
  }, [abc, genres, sort, startsAt]);
  /*
  useEffect(() => {
    const update = {};
    if (genres) update.genres = genres.join();
    if (!abc) delete update.startsWith;
    if (!sort) delete update.sortBy;
    if (!genres || genres.length ===0) delete update.genres;
    if (abc) update.startsWith = abc;
    if (sort) update.sortBy = sort;
    history.push({
      search: "?"+ new URLSearchParams(update).toString(),
    })
  },[genres])
  */
  /*
  useEffect(() => {
    const update = {};
    if (genres) update.genres = genres.join();
    if (!abc) delete update.startsWith;
    if (!sort) delete update.sortBy;
    if (!genres || genres.length ===0) delete update.genres;
    if (abc) update.startsWith = abc;
    if (sort) update.sortBy = sort;
    history.push({
      search: "?"+ new URLSearchParams(update).toString(),
    })
  },[sort])*/

  const openFilter = (id) => {
    if (open !== id) {
      setOpen(id);
    } else {
      setOpen();
    }
  };

  const handleABC = (e) => {
    if (e.target.id !== "abcMenu") {
      setABC(e.target.innerHTML.toUpperCase());
      setStartsAt();
      setOpen();
    }
  };

  const handleGenres = (e) => {
    openFilter("genres");
    if (e.target.value !== "") {
      if (e.target.checked) {
        let tmp = [];
        if (genres) tmp = [...genres];
        tmp.push(e.target.value);
        setGenres(tmp);
        setStartsAt();
      } else {
        const tmp = genres.filter((item) => item !== e.target.value);
        setGenres(tmp);
        setStartsAt();
      }
    } else {
      setGenres([]);
      setStartsAt();
      const check = document.getElementsByClassName("checkbox");
      for (let i = 0; i < check.length; i += 1) {
        if (check[i].id !== "selectall") {
          check[i].checked = false;
        }
      }
    }
  };
  const [userMenuOpened, setUserMenuOpened] = useState();

  useEffect(() => {
    if (
      location.pathname.indexOf("movies") > -1 ||
      location.pathname.indexOf("series") > -1 ||
      location.pathname.indexOf("new-episodes") > -1
    ) {
      setShowFilters(true);
    } else {
      setShowFilters(false);
    }
  }, [location.pathname]);
  const history = useHistory();
  if (
    location.pathname === "/login" ||
    location.pathname === "/register" ||
    location.pathname === "/forgot-password" ||
    location.pathname === "/auth" ||
    location.pathname.split("/")[1] === "forgot-password"
  )
    return null;

  const isChecked = (string) => {
    if (!genres || genres.length === 0) return false;
    return genres.indexOf(string) > -1;
  };
  const isAll = () => {
    if (!genres || genres.length === 0) return true;
    return false;
  };

  const handleSearchClick = (url) => {
    setShowSearchResults(false);
    window.location.href = url;
  };

  const onSearchSubmit = (e) => {
    e.preventDefault();
    if (search.length < 3) return;
    window.location.href = "/search?s=" + search;
  };

  return (
    <HeaderWrapper>
      <Link to="/">
        <Logo className="logo">
          <img src={logo} style={{ width: "100px" }} />
        </Logo>
      </Link>
      <Filters>
        {showFilters && (
          <>
            <li
              className={`dropdown abc-filter${open === "abc" ? " open" : ""}`}
            >
              <div
                id="abc"
                className="dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="true"
                onClick={() => openFilter("abc")}
              >
                ABC
                <span className="value" style={{ pointerEvents: "none" }}>
                  {" "}
                  {abc || "All"}
                </span>
                <i
                  className="fas fa-chevron-down"
                  style={{ pointerEvents: "none" }}
                />
              </div>
              <ul id="abcMenu" className="dropdown-menu" onClick={handleABC}>
                <li>0-9</li>
                <li>a</li>
                <li>b</li>
                <li>c</li>
                <li>d</li>
                <li>e</li>
                <li>f</li>
                <li>g</li>
                <li>h</li>
                <li>i</li>
                <li>j</li>
                <li>k</li>
                <li>l</li>
                <li>m</li>
                <li>n</li>
                <li>o</li>
                <li>p</li>
                <li>q</li>
                <li>r</li>
                <li>s</li>
                <li>t</li>
                <li>u</li>
                <li>v</li>
                <li>w</li>
                <li>x</li>
                <li>y</li>
                <li>z</li>
                <li>All</li>
              </ul>
            </li>
            <li
              className={`dropdown genre-filter${
                open === "genre" ? " open" : ""
              }`}
            >
              <div
                id="genre"
                className="dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
                onClick={() => openFilter("genre")}
              >
                Genre
                <span className="value">
                  {(genres &&
                    genres.length > 0 &&
                    genres[0] !== "" &&
                    genres.length === 1) ||
                  (genres && genres.length > 1)
                    ? "Selected"
                    : "All"}
                </span>
                <i className="fas fa-chevron-down" />
              </div>
              <ul
                className="dropdown-menu dropdown-menu-large"
                onClick={() => openFilter("genre")}
              >
                <li>
                  <input
                    id="action"
                    type="checkbox"
                    name="genre"
                    value="28"
                    checked={isChecked("28")}
                    className="checkbox"
                    onChange={handleGenres}
                  />
                  <label htmlFor="action">Action</label>
                </li>
                <li>
                  <input
                    id="adventure"
                    type="checkbox"
                    name="genre"
                    value="12"
                    checked={isChecked("12")}
                    className="checkbox"
                    onChange={handleGenres}
                  />
                  <label htmlFor="adventure">Adventure</label>
                </li>
                <li>
                  <input
                    id="animation"
                    type="checkbox"
                    name="genre"
                    value="16"
                    checked={isChecked("16")}
                    className="checkbox"
                    onChange={handleGenres}
                  />
                  <label htmlFor="animation">Animation</label>
                </li>
                <li>
                  <input
                    id="comedy"
                    type="checkbox"
                    name="genre"
                    value="35"
                    checked={isChecked("35")}
                    className="checkbox"
                    onChange={handleGenres}
                  />
                  <label htmlFor="comedy">Comedy</label>
                </li>
                <li>
                  <input
                    id="crime"
                    type="checkbox"
                    name="genre"
                    value="80"
                    checked={isChecked("80")}
                    className="checkbox"
                    onChange={handleGenres}
                  />
                  <label htmlFor="crime">Crime</label>
                </li>
                <li>
                  <input
                    id="documentary"
                    type="checkbox"
                    name="genre"
                    value="99"
                    checked={isChecked("99")}
                    className="checkbox"
                    onChange={handleGenres}
                  />
                  <label htmlFor="documentary">Documentary</label>
                </li>
                <li>
                  <input
                    id="drama"
                    type="checkbox"
                    name="genre"
                    value="18"
                    checked={isChecked("18")}
                    className="checkbox"
                    onChange={handleGenres}
                  />
                  <label htmlFor="drama">Drama</label>
                </li>
                <li>
                  <input
                    id="family"
                    type="checkbox"
                    name="genre"
                    value="10751"
                    checked={isChecked("10751")}
                    className="checkbox"
                    onChange={handleGenres}
                  />
                  <label htmlFor="family">Family</label>
                </li>
                <li>
                  <input
                    id="fantasy"
                    type="checkbox"
                    name="genre"
                    value="14"
                    checked={isChecked("14")}
                    className="checkbox"
                    onChange={handleGenres}
                  />
                  <label htmlFor="fantasy">Fantasy</label>
                </li>
                <li>
                  <input
                    id="history"
                    type="checkbox"
                    name="genre"
                    value="36"
                    checked={isChecked("36")}
                    className="checkbox"
                    onChange={handleGenres}
                  />
                  <label htmlFor="history">History</label>
                </li>
                <li>
                  <input
                    id="horror"
                    type="checkbox"
                    name="genre"
                    value="27"
                    checked={isChecked("27")}
                    className="checkbox"
                    onChange={handleGenres}
                  />
                  <label htmlFor="horror">Horror</label>
                </li>
                <li>
                  <input
                    id="music"
                    type="checkbox"
                    name="genre"
                    value="10402"
                    checked={isChecked("10402")}
                    className="checkbox"
                    onChange={handleGenres}
                  />
                  <label htmlFor="music">Music</label>
                </li>
                <li>
                  <input
                    id="mystery"
                    type="checkbox"
                    name="genre"
                    value="9648"
                    checked={isChecked("9648")}
                    className="checkbox"
                    onChange={handleGenres}
                  />
                  <label htmlFor="mystery">Mystery</label>
                </li>
                <li>
                  <input
                    id="romance"
                    type="checkbox"
                    name="genre"
                    value="10749"
                    checked={isChecked("10749")}
                    className="checkbox"
                    onChange={handleGenres}
                  />
                  <label htmlFor="romance">Romance</label>
                </li>
                <li>
                  <input
                    id="science_fiction"
                    type="checkbox"
                    name="genre"
                    value="878"
                    checked={isChecked("878")}
                    className="checkbox"
                    onChange={handleGenres}
                  />
                  <label htmlFor="science_fiction">Science Fiction</label>
                </li>
                <li>
                  <input
                    id="thriller"
                    type="checkbox"
                    name="thriller"
                    value="53"
                    checked={isChecked("53")}
                    className="checkbox"
                    onChange={handleGenres}
                  />
                  <label htmlFor="thriller">Thriller</label>
                </li>
                <li>
                  <input
                    id="war"
                    type="checkbox"
                    name="war"
                    value="10752"
                    checked={isChecked("10752")}
                    className="checkbox"
                    onChange={handleGenres}
                  />
                  <label htmlFor="war">War</label>
                </li>
                <li>
                  <input
                    id="western"
                    type="checkbox"
                    name="western"
                    value="37"
                    checked={isChecked("37")}
                    className="checkbox"
                    onChange={handleGenres}
                  />
                  <label htmlFor="western">Western</label>
                </li>
                <li>
                  <input
                    id="selectall"
                    type="checkbox"
                    name=""
                    value=""
                    checked={isAll()}
                    onChange={handleGenres}
                  />
                  <label htmlFor="selectall">Select all</label>
                </li>
                <li />
              </ul>
            </li>
            <li
              className={`dropdown sort-filter${
                open === "sort" ? " open" : ""
              }`}
            >
              <div
                id="sort"
                className="dropdown-toggle"
                data-toggle="dropdown"
                onClick={() => openFilter("sort")}
              >
                Sort by
                <span className="value" style={{ textTransform: "capitalize" }}>
                  {sort ? sort : "Popularity"}
                </span>
                <i className="fas fa-chevron-down" />
              </div>
              <ul className="dropdown-menu" onClick={() => openFilter("sort")}>
                <li
                  onClick={() => {
                    setSort("popularity");
                    setStartsAt();
                  }}
                >
                  Popularity
                </li>
                <li
                  onClick={() => {
                    setSort("recent");
                    setStartsAt();
                  }}
                >
                  Recent
                </li>
                <li
                  onClick={() => {
                    setSort("rating");
                    setStartsAt();
                  }}
                >
                  Rating
                </li>
                <li
                  onClick={() => {
                    setSort("year");
                    setStartsAt();
                  }}
                >
                  Year
                </li>
              </ul>
            </li>
          </>
        )}
        <li
          className={`dropdown search${
            searchedFilms.length > 0 && showSearchResults ? " open" : ""
          }`}
        >
          <form
            role="form"
            autoComplete="off"
            style={{ paddingLeft: "10px" }}
            onClick={() => setShowSearchResults(!showSearchResults)}
            onSubmit={onSearchSubmit}
          >
            <i className="fas fa-search" />
            <input
              id="search"
              type="search"
              value={search || ""}
              onChange={(e) => setSearch(e.target.value)}
              className="search-input"
              placeholder="Search..."
            />
          </form>
          <ul className="dropdown-menu">
            {searchedFilms.map((item) => {
              let url =
                "/movie/" +
                item.imdb_id +
                "-" +
                encodeURI(item.title.split(" ").join("-"));
              if (item.tv_id)
                url =
                  "/serie/" +
                  item.tv_id +
                  "-" +
                  encodeURI(item.title.split(" ").join("-"));
              return (
                <li
                  style={{ display: "flex", padding: "4px", maxHeight: "78px" }}
                  onClick={() => handleSearchClick(url)}
                >
                  <img
                    src={`https://image.tmdb.org/t/p/w342${item.poster}`}
                    style={{ width: "45px", marginRight: "10px" }}
                  />
                  <div
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {item.title}
                    <br />
                    <span
                      style={{
                        backgroundColor: "rgba(255,255,255,0.4)",
                        padding: "5px",
                        marginTop: "5px",
                      }}
                    >
                      {item.release_date && item.release_date.substring(0, 4)}
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        </li>
      </Filters>
      <HeaderNav>
        {user && user.token && (
          <>
            <li
              className="user-normal hamburger"
              onClick={() => setMobileMenu(!mobileMenu)}
            >
              <div className="dropdown-toggle btn-alt center-text">
                <span>
                  <i className="fas fa-bars" style={{ fontSize: "26px" }} />
                </span>
              </div>
            </li>
            <li className="user-normal dropdown">
              <Link to="/user/invite">
                <div className="dropdown-toggle btn-alt center-text">
                  {" "}
                  <i className="fas fa-link" />
                  <span>Referrals</span>
                </div>
              </Link>
            </li>
            {statistics && statistics.chatUrl && (
              <li className="user-normal dropdown">
                <a href={statistics.chatUrl} target="_blank">
                  <div className="dropdown-toggle btn-alt center-text">
                    {" "}
                    <i className="fas fa-comment-dots" />
                    <span>Chat</span>
                  </div>
                </a>
              </li>
            )}

            <li
              className={`user-normal dropdown${userMenuOpened ? " open" : ""}`}
              onClick={() => setUserMenuOpened(!userMenuOpened)}
            >
              <div
                className="dropdown-toggle btn-alt"
                data-toggle="dropdown"
                aria-expanded="false"
                style={{ width: "100%" }}
              >
                <i className="fas fa-user" />
                <span className="username">{user.username}</span>
                <i className="fas fa-chevron-down" />
              </div>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/user">
                    <i className="fas fa-user" />
                    Settings
                  </Link>
                </li>
                <li>
                  <Link to="/user/invite">
                    <i className="fas fa-link" />
                    Referrals
                  </Link>
                </li>
                <li>
                  <Link to="/user/watchlist">
                    <i className="fas fa-history" />
                    Watchlist
                  </Link>
                </li>
                <li>
                  <Link to="/user/favorites">
                    <i className="fas fa-heart" />
                    Favorites
                  </Link>
                </li>
                <li>
                  <a
                    href="/session/logout"
                    onClick={(e) => {
                      e.preventDefault();
                      logout();
                    }}
                  >
                    <i className="fas fa-sign-out-alt" />
                    Sign out
                  </a>
                </li>
              </ul>
            </li>
          </>
        )}
        {(!user || (user && !user.token)) && (
          <li className="user-normal dropdown">
            <div
              className="dropdown-toggle btn-alt"
              data-toggle="dropdown"
              aria-expanded="false"
              onClick={() => history.push("/login")}
            >
              <i className="fas fa-user" />
              <span className="username"> Login </span>
              <i className="fas fa-chevron-down" />
            </div>
          </li>
        )}
      </HeaderNav>
      {mobileMenu && (
        <SidebarWrapper>
          <div
            style={{
              width: "100%",
              background: "rgb(39, 39, 39)",
            }}
          >
            <NavElement onClick={() => setMobileMenu(false)}>
              <li className={`${location.pathname === "/" ? "active" : ""}`}>
                <Link to="/">
                  <i className="fas fa-home" />
                  <span>Home</span>
                </Link>
              </li>
              <li
                className={`${location.pathname === "/movies" ? "active" : ""}`}
              >
                <Link to="/movies">
                  <i className="fas fa-film" />
                  <span>Movies</span>
                </Link>
              </li>
              <li
                className={`${location.pathname === "/series" ? "active" : ""}`}
              >
                <Link to="/series">
                  <i className="fas fa-tv" />
                  <span>TV Series</span>
                </Link>
              </li>
              <li
                className={`${
                  location.pathname === "/premium" ? "active" : ""
                }`}
              >
                <Link to="/premium">
                  <i className="fas fa-star" />
                  <span>Premium</span>
                </Link>
              </li>
              <li
                className={`${location.pathname === "/user" ? "active" : ""}`}
              >
                <Link to="/user">
                  <i className="fas fa-user" />
                  <span>Settings</span>
                </Link>
              </li>
              <li
                className={`${location.pathname === "/user" ? "active" : ""}`}
              >
                <a
                  href="https://t.me/joinchat/P1JM7hsNnGDbM10eoKxdmA"
                  target="_blank"
                >
                  <i className="fas fa-commenting-o" />
                  <span>Chat</span>
                </a>
              </li>
              {(!user || (user && !user.token)) && (
                <li
                  className={`${
                    location.pathname === "/contact" ? "active" : ""
                  }`}
                >
                  <Link to="/login">
                    <i className="fas fa-sign-in-alt" />
                    <span>Login</span>
                  </Link>
                </li>
              )}
              {user && user.token && (
                <li
                  className={`${
                    location.pathname === "/contact" ? "active" : ""
                  }`}
                >
                  <a
                    href="/session/logout"
                    onClick={(e) => {
                      e.preventDefault();
                      logout();
                    }}
                  >
                    <i className="fas fa-sign-out-alt" />
                    <span>Sign out</span>
                  </a>
                </li>
              )}
            </NavElement>
          </div>
        </SidebarWrapper>
      )}
    </HeaderWrapper>
  );
};

export default Header;

const SidebarWrapper = styled.div`
  @media screen and (min-width: 771px) {
    display: none;
  }
  position: fixed;
  background: rgb(39, 39, 39) !important;
  width: 100%;
  height: inherit;
  top: 70px;
  left: 0;
  pointer-events: all;
  color: white;
  z-index: 20;
`;

const NavElement = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: rgb(39, 39, 39) !important;
  margin: 0 auto;

  & li {
    display: block;
    font-size: 14px;
    width: 100px;
    @media screen and (max-width: 760px) {
      float: left;
    }
  }

  & li a {
    display: block;
    color: rgba(255, 255, 255, 0.7);
    padding: 6px 4px;
    text-decoration: none;
  }

  & li a:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.2);
  }

  & li.active a {
    color: #fff;
    background: rgb(41, 142, 234);
  }

  & li a i {
    display: block;
    text-align: center;
    font-size: 15px;
    padding-bottom: 2px;
  }

  & li a span {
    display: block;
    line-height: 18px;
    text-align: center;
  }
`;

import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled'

const DownloadsIcon = styled.div`
position: fixed;
bottom: 20px;
right: 20px;
width: 50px;
height: 50px;
border-radius: 50%;
z-index: 1000000;
background: #298eea;
color: white;
font-size: 20px;
line-height: 42px;

 div {
   font-size: 12px;
   background: red;
   width: 17px;
   height: 17px;
   border-radius: 50%;
   position: absolute;
   top: 0;
   right: 0;
   color: white;
   line-height: 14px;
   font-weight: 800;
 }
`;

const Downloads = () => {
const downloads = useSelector(redux => redux.downloads);
if (!downloads) return null;
return (
  <DownloadsIcon>
    <div>
      {downloads}
    </div>
    <i className="fas fa-download" />
  </DownloadsIcon>
)
};

export default Downloads;

import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';

import MovieItem from './MovieItem';
import { API_URL } from '../config';

const Similar = styled.section`
  position: relative;
  width: 100%;
  padding-top: 30px;
  margin-top: 30px;
  vertical-align: top;
  z-index: 1;
  border-top: 1px solid rgb(48, 48, 48);
  text-align:left;

  & h4{
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
  }
`;


const SimilarMovies = (movie) => {
  const [similar, setSimilar] = useState([]);

  const getSimilar = (mov) => {
    if (mov && !mov.empty) {
      const gen = mov.genres[0] ? mov.genres[0].name : '';
      axios.get(`${API_URL}movies/?genres.name=${gen}`)
        .then((res) => {
          const shuffled = res.data.sort(() => 0.5 - Math.random());
          const tmp = shuffled.slice(0, 6);
          setSimilar(tmp);
        });
    }
  };

  useEffect(() => {
    getSimilar(movie.movie);
  },[]);

  return (
    <Similar>
    <h4>You might also like..</h4>
    {similar && similar.map((item) => (
      <MovieItem item={item} reload/>
    ))}
  </Similar>
  );
};

export default SimilarMovies;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const MainWrapper = styled.div`
position: relative;
color:white;
display: block;
vertical-align: top;
background: #1b1b1b;
width: calc(100% - 100px);
min-height: 100%;
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
margin: 70px 0 0 100px;
height:100vh;
z-index: 1;
`;

const InnerContainer = styled.section`
  padding: 0 30px;
  margin: auto;
  padding-bottom: 30px;
  margin-bottom: 45px;
  width: 80%;
  max-width: 1300px;
  position: relative;
  padding-top: 30px;
  text-align: center;

  & h1 {
    font-size: 35px;
    margin-bottom: 25px;
    font-weight: 300;
    line-height: 1.5em;
  }
`;

const AccountTabs = styled.ul`
  border-bottom: 1px solid rgba(255,255,255,.1); 
  list-style: none;

  & li {
    display: inline-block;
    font-size: 15px;
    margin-bottom: -2px;
  }

  & li a {
    display: inline-block;
    color: rgba(255,255,255,.7);
    font-weight: 600;
    padding: 0 20px;
    border-bottom: 1px solid transparent;
    text-decoration: none;
    transition: .3s ease;
  }

  & li a:hover span {
    border-color: rgba(255,255,255,0.2);
  }

  & li a span {
      display: inline-block;
      padding: 15px 0px;
      border-bottom: 1px solid transparent;
  }

  & li.active a span {
    color: #fff;
    border-color: #fff;
  }
`;

const ItemWrapper = styled.div`
position: relative;
font-size: 15px;
display: inline-block;
width: calc(100% / 11);
padding: 10px;
text-align: left;
-webkit-perspective: 1000;
-moz-perspective: 1000;
-ms-perspective: 1000;
perspective: 1000;
-ms-transform: perspective(1000px);
-moz-transform: perspective(1000px);
-moz-transform-style: preserve-3d;
-ms-transform-style: preserve-3d;
@media (max-width: 1920px) {
  width: calc(100% / 9);
}
@media (max-width: 1700px) {
  width: calc(100% / 8);
}
@media (max-width: 1400px) {
  width: calc(100% / 7);
}
@media (max-width: 1200px){
  width: calc(100% / 6);
}

& > div {
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -moz-transition: 0.6s;
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

& .thumb {
  background-position: left top;
  background-repeat: no-repeat;
  border-radius: 4px;
  overflow: hidden;
}

& .hide{
  position: absolute;
  top: 0;
  width: 100%;
  height:100%;
  padding:10px;
  left:0;
  }

&:hover .hide {
  opacity:1;
}
`;

const ItemInner = styled.div`
  opacity:0;
  z-index: 2;
  transform: rotateY(0deg);

  &:hover {
    opacity:1;
  }

  & a {
    position: relative;
    display: block;
    transition: .3s ease;
    text-decoration: none;
    height:100%;
  }
`;

const MovieOptions = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  transition: .3s ease;
  z-index: 20;
  width:100%;

  & .watchlist-remove{
    top: 0;
    left: 0;
    right: 0;
    padding-left: 50px;
    background: rgba(0,0,0, 0.35);
    text-align: center;
    border-radius: 3px 3px 0 0;
    transition: .3s ease;
    display: inline-block;
    position: absolute;
    z-index: 20;
  }

  & .watchlist-remove:hover {
    cursor: pointer;
  }

  & .watchlist-remove i {
    position: absolute;
    left: 0;
    padding: 4px 5px 6px 5px;
    color: #fff;
    background: rgba(0,0,0,.1);
    border-radius: 3px 0 0 3px;
  }

  & .watchlist-remove:hover > i {
    color: #fff;
  }

  & .watchlist-remove span {
    display: inline-block;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    vertical-align: top;
    margin-left: -20px!important;
  }

  & watchlist-remove:hover > span {
    color: #fff;
  }

  & i {
    display: inline-block;
    font-size: 20px;
  }
`;

const MoviePlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: .3s ease;
  z-index: 10;

  & i {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    display: inline-block;
    font-size: 20px;
    text-align: center;
    width: 48px;
    line-height: 44px;
    border: 2px solid #fff;
    border-radius: 50%;
    transform: translate(-50%,-50%);
  }
`;

const Request = (props) => (
  <MainWrapper>
    <InnerContainer>
      <h1>Request Movie / TV List</h1>
      <AccountTabs>
        <li><Link to="/request"><span>View all request</span></Link></li>
        <li><Link to="/request?filled=1"><span>View filled request</span></Link></li>
        <li className="active"><Link to="/request/add"><span>Make a new Request</span></Link></li>
      </AccountTabs>
      <ItemWrapper>
        <div className="thumb">
          <img
            src="https://image.tmdb.org/t/p/w342/680X9apSqmAcebLg8evnnUeQNeI.jpg"
            style={{ width: '100%' }}
            alt="title"
          />
        </div>
        <ItemInner className="hide">
          <div style={{
            position: 'relative',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.4)',
          }}
          >
            <MovieOptions>
              <div className="watchlist-remove">
                <i className="fas fa-times" />
                <span>Remove</span>
              </div>
            </MovieOptions>
            <a href="">
              <MoviePlay>
                <i className="fas fa-play" />
              </MoviePlay>
            </a>
          </div>
        </ItemInner>
      </ItemWrapper>
    </InnerContainer>
  </MainWrapper>

);

export default Request;